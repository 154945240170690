import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

let lifeData = JSON.parse(localStorage.getItem('lifeData'))||{}
function saveLifeData (key,value){
  lifeData[key] = value;
  localStorage.setItem('lifeData',JSON.stringify(lifeData))
}

const store = new Vuex.Store({
  state: {
    searchInput:lifeData.searchInput|| '',
    isEquipment:'pc', //phone or pc
    PhoneNavShow:lifeData.PhoneNavShow|| false, 
    zhuan_lun:lifeData.zhuan_lun||'',
    bksj_wo:lifeData.bksj_wo||'',
    smallPc:lifeData.smallPc|| '',
    tuishin:lifeData.tuishin||'',
    // bksj_rong:'',
    isSmallPc:false
  },
  mutations: {
    setIsSearchInput: function(state,newString){
      state.searchInput = newString
      saveLifeData('searchInput',state.searchInput)
    },
    setIsEquipment: function(state,newString){
      state.isEquipment = newString
    },
    setIsSmallPc: function(state,newBoolean){
      state.isSmallPc = newBoolean
    },
    setPhoneNavShow: function(state,newString){
      state.PhoneNavShow = newString
      saveLifeData('PhoneNavShow',state.PhoneNavShow)
    },
    setSmallPc: function(state,newString){
      state.smallPc = newString
      saveLifeData('smallPc',state.smallPc)
    },
    settuishin: function(state,newString){
      state.tuishin = newString
      saveLifeData('tuishin',state.tuishin)
    }
    // increment (state) {
    //   state.count++;
    // }
  }
});

export default store;