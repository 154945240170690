<template>
  <div class="content content-first">
    <div v-if="$store.state.isEquipment === 'pc'" class="swiperWrap">
      <Swiper :options="swiperOption" v-if="list1.length">
        <SwiperSlide v-for="(page, index) in list1" :key="index" class="page">
          <el-row :gutter="20">
            <el-col :span="12" class="singleItem" :class="'singleItem' + index" v-for="(item, index) in page"
              :key="item.id">
              <router-link class="crad" target="_blank" :to="{
                path: '/xq',
                query: {
                  qing: item.id,
                  mian: `shx${0}`,
                },
              }">
                <img v-lazy="item.pub_cover" alt="" />
                <div class="imgRightBox">
                  <p class="name">{{ item.FUBIAOTI }}</p>
                  <p class="title1">{{ item.SYS_TOPIC }}</p>
                  <p class="text">
                    {{ $AwayTell(item.DESCRIPTION) }}
                  </p>
                </div>
              </router-link>
            </el-col>
          </el-row>
        </SwiperSlide>
        <div class="swiper-pagination" slot="pagination"></div>
      </Swiper>
      <div class="arrows">
        <img src="@/assets/image/home/图层 16.png" alt="" class="swiper-button-next" />
        <img src="@/assets/image/home/图层 16.png" alt="" class="swiper-button-prev" style="transform: rotateZ(180deg)" />
      </div>
    </div>
    <div v-else class="phone">
      <Swiper :options="swiperOption_phone" v-if="list.length">
        <SwiperSlide v-for="(item, index) in list" :key="index">
          <router-link class="crad" target="_blank" :to="{
            path: '/xq',
            query: {
              qing: item.id,
              mian: `shx${0}`,
            },
          }">
            <img :src="item.pub_cover" v-if="index == 0 || index == (list.length - 1)">
            <img v-lazy="item.pub_cover" alt="" v-else>
            <div>
              <p class="name">{{ item.FUBIAOTI }}</p>
              <p class="title1">{{ item.SYS_TOPIC }}</p>
              <p class="text">
                {{ $AwayTell(item.DESCRIPTION) }}
              </p>
            </div>
          </router-link>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script>
import api from "@/api/index.js";
export default {
  data() {
    return {
      swiperOption: {
        // loop: true,
        initialSlide: 0,
        EffectCards: true,
        slidesPerView: this.$store.state.smallPc ? 2 : 1,
        spaceBetween: 20,
        navigation: {
          nextEl: ".swiper-button-prev",
          prevEl: ".swiper-button-next",
        },
      },
      swiperOption_phone: {
        loop: true,
        EffectCards: true,
        slidesPerView: 1,
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false,
        // },
      },
    };
  },
  props: ['list'],
  computed: {
    list1() {
      let result = [];
      if (!this.list) { return [] }
      for (let index = 0; index < this.list.length / 4; index++) {
        result.push(this.list.slice(index * 4, (index + 1) * 4));
      }
      return result;
    },
  },
  created() {
    // this.getList1();
    console.log('list1', this.list1)
  },
  methods: {
    getList1() {
      api
        .post("spc/cms/publish/queryList.do", {
          s: 2,
          c: this.$route.name === 'index' ? 62 : 70,
        })
        .then((res) => {
          this.list = res.result;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./common.scss";

.content-first {
  .swiperWrap {
    height: 703px;
    position: relative;
  }

  .crad {
    background-image: url("../../assets/image/home/蓝色次背景.png");
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 0.8rem 1rem;

    img {
      width: 16.25rem;
      height: 16.25rem;
      border-radius: 50%;
    }

    .imgRightBox {
      width: calc(100% - 17.25rem)
    }
  }

  .phone .crad {
    img {
      width: 93%;
      margin: auto;
      aspect-ratio: 1/1;
      border-radius: 50%;
      display: block;
    }
  }
}

@media screen and(max-width:1440px) {
  .content-first {
    .swiperWrap {
      height: 618px;
    }
  }
}
</style>