<!-- 报刊视界 -->
<template>
  <div
    class="newsstand"
    :class="$store.state.isEquipment == 'pc' ? '' : 'phone'"
    v-if="bksj_list.length"
  >
    <el-row type="flex" justify="center">
      <el-col>
        <router-link target="_blank" to="/bksj">
          <HomeHeadline :title="require('@/assets/image/home/报刊视界.png')" />
        </router-link>

        <div class="content">
          <el-row :gutter="$store.state.isEquipment == 'pc' ? 20 : 0">
            <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
              <div class="left_top">
                <!-- <img src="@/assets/image/home/707.png" alt="" /> -->

                <div class="head">
                  <div class="headl" v-if="$store.state.isEquipment !== 'pc'">
                    <img src="../../assets/image/line.png" alt="" />
                    <div class="round"></div>
                  </div>
                  <div class="cent">
                    <router-link target="_blank" :to="{ path: '/woshuo' }">
                      <!-- 点击我说跳转 -->
                      <img src="../../assets/image/woshuo.png" />
                      <span>我说</span>
                    </router-link>
                  </div>
                  <div class="headl">
                    <div class="round"></div>
                    <img src="../../assets/image/line.png" alt="" />
                  </div>
                </div>

                <div
                  class="left_bottom"
                  v-for="item in bksj_list"
                  :key="item.index"
                >
                  <el-row :gutter="$store.state.isEquipment == 'pc' ? 21 : 0">
                    <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                      <router-link
                        target="_blank"
                        :to="{
                          path: '/xq',
                          query: {
                            qing: item.id,
                            mian: 'shou_bk',
                          },
                        }"
                      >
                        <img v-lazy="item.pub_cover" alt="" />
                      </router-link>
                    </el-col>
                    <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18">
                      <router-link
                        target="_blank"
                        :to="{
                          path: '/xq',
                          query: {
                            qing: item.id,
                            mian: 'shou_bk',
                          },
                        }"
                      >
                        <p class="title">{{ item.SYS_TOPIC }}</p>
                        <span class="text">{{
                          $AwayTell(
                            item.DESCRIPTION,
                            0,
                            $store.state.isSmallPc ? 60 : 124
                          )
                        }}</span>
                        <span class="particulars">[详情]</span>
                      </router-link>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="right_top">
                <!-- <img src="@/assets/image/home/706.png" alt="" /> -->
                <div class="head">
                  <div class="headl">
                    <img src="../../assets/image/line.png" alt="" />
                    <div class="round"></div>
                  </div>
                  <div class="cent">
                    <router-link
                      target="_blank"
                      :to="{ path: '/bksjList?id=48' }"
                    >
                      <!-- 点击专题跳转 -->
                      <img src="../../assets/image/ping.png" />
                      <span>融合报道</span>
                    </router-link>
                  </div>
                  <div class="headl">
                    <div class="round"></div>
                    <img src="../../assets/image/line.png" alt="" />
                  </div>
                </div>
              </div>

              <div class="right_bottom" v-for="a in rong_first" :key="a.index">
                <router-link
                  target="_blank"
                  :to="{
                    path: '/xq',
                    query: {
                      qing: a.id,
                      mian: 'shou_bkr',
                    },
                  }"
                  class="first"
                >
                  <img v-lazy="a.pub_cover" alt="" class="right_bottom_img" />
                  <p class="right_title">{{ $AwayTell(a.SYS_TOPIC, 0, 43) }}</p>
                </router-link>

                <div class="bottom_text">
                  <ul>
                    <li v-for="item in rong_sheng" :key="item.index">
                      <router-link
                        target="_blank"
                        :to="{
                          path: '/xq',
                          query: {
                            qing: item.id,
                            mian: 'shou_bkr',
                          },
                        }"
                      >
                        <span>{{ item.SYS_TOPIC }}</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import HomeHeadline from "@/components/HomeHeadline.vue";
import api from "@/api/index.js";
export default {
  components: {
    HomeHeadline,
  },
  props: {},
  data() {
    return {
      bksj_list: "",
      rong_first: [],
      rong_sheng: "",
    };
  },
  computed: {},
  methods: {},
  mounted() {
    // 首页-报刊视界推荐-我说
    api
      .post("spc/cms/publish/queryList.do", {
        s: 2,
        c: 53,
      })
      .then((res) => {
        if (this.$store.state.isEquipment == "pc") {
          this.bksj_list = res.result.slice(0, 3);
        } else {
          this.bksj_list = res.result.slice(0, 1);
        }
      });
    // 首页-报刊视界推荐-融合报道
    api
      .post("spc/cms/publish/queryList.do", {
        s: 2,
        c: 54,
      })
      .then((res) => {
        console.log(res.result);
        // 第一个
        this.rong_first.push(res.result.shift());
        // 剩下的
        if (
          this.$store.state.isEquipment == "pc" &&
          !this.$store.state.isSmallPc
        ) {
          this.rong_sheng = res.result.slice(0, 5);
        } else {
          this.rong_sheng = res.result.slice(0, 3);
        }
      });
  },
};
</script>
<style scoped lang="scss">
.newsstand {
  // padding: 30px 0 0 0;
  background: url("../../assets/image/home/pic(7).png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .content {
    // padding: 3.125rem;
    margin-top: 1rem;

    .left_top,
    .right_top {
      img {
        width: 100%;
      }
    }
    .left_top {
      .head {
        width: 100%;
        // height: 2.625rem;
        display: flex;
        // justify-content: center;

        .headl {
          display: flex;
          align-items: center;
          flex-grow: 1;

          img {
            float: left;
            width: calc(100% - 10px);
            height: 0.0625rem;
          }

          .round {
            width: 0.625rem;
            height: 0.625rem;
            border: 1px solid #d6d6d6;
            border-radius: 50%;
            float: left;
          }
        }

        .cent {
          display: flex;
          line-height: 1.9rem;
          margin: 0 1rem;
          cursor: pointer;
          white-space: nowrap;

          img {
            width: 1.3rem;
            height: 2rem;
            vertical-align: bottom;
          }

          span {
            font-size: 1.875rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #212121;
            margin-left: 0.3125rem;
            vertical-align: bottom;
          }
        }
      }
    }
    .right_top {
      .head {
        width: 100%;
        // height: 2.625rem;
        display: flex;
        justify-content: center;
        overflow: hidden;

        .headl {
          display: flex;
          align-items: center;

          img {
            float: left;
            width: calc(100% - 10px);
            height: 0.0625rem;
          }

          .round {
            width: 0.625rem;
            height: 0.625rem;
            border: 1px solid #d6d6d6;
            border-radius: 50%;
            float: left;
          }
        }

        .cent {
          display: flex;
          line-height: 1.9rem;
          margin: 0 1rem;
          cursor: pointer;
          white-space: nowrap;

          img {
            width: 1.8rem;
            height: 1.8rem;
            vertical-align: bottom;
          }

          span {
            font-size: 1.875rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #212121;
            margin-left: 0.3125rem;
            vertical-align: bottom;
          }
        }
      }
    }
    .right_top {
      margin: 0 0 0.5rem 0;
    }

    .left_bottom {
      padding: 1.1rem 0;

      border-bottom: 1px solid #c8d5e4;

      img {
        width: 100%;
        aspect-ratio: 4/3;
        height: 162px;
        // height: 9.9rem;
        // height: 9.75rem;
        display: block;
      }

      .title {
        font-size: 1.25rem;
        font-weight: bold;
        color: #212121;
        line-height: 3rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .title:hover {
        color: #277398;
      }

      .text {
        font-size: 1rem;
        font-weight: 400;
        color: #707070;
        line-height: 1.875rem;
      }

      .particulars {
        color: #287498;
        font-size: 1rem;
        cursor: pointer;
      }
    }

    .right_bottom {
      background: #f5f5f5;
      margin-top: 1rem;

      img {
        width: 452px;
        aspect-ratio: 4/3;
        cursor: pointer;
        display: block;
        margin: auto;
        max-width: 100%;
        height: 339px;
      }
      .first {
        height: 381px;
        display: block;
        font-size: 1.25rem;
        color: #212121;
        font-weight: bold;
        text-align: justify;
      }

      .first:hover {
        color: #277398;
      }
      .right_title {
        font-size: 1.25rem;
        font-weight: bold;
        color: #212121;
        line-height: 42px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 0.5rem;
      }
      .bottom_text {
        // width: 100%;
        padding: 0.6rem;
        border-top: 1px solid #c8d5e4;

        hr {
          margin: 0.3rem 0 0 0;
          border: 1px solid #bacbdd;
        }

        ul {
          li {
            span {
              color: #000000;
            }
            span:hover {
              color: #277398;
            }
            font-size: 1.125rem;
            color: #212121;
            line-height: 34px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          li::before {
            content: "\2022 ";
            font-size: 1.5rem;
            color: #a08f88;
            margin-right: 0.3125rem;
          }
        }
      }
      @media screen and(max-width:1440px) {
        .first {
          height: auto;
          img {
            height: 231px;
            width: auto;
            display: block;
            margin: auto;
          }
        }
        .bottom_text ul li {
          line-height: 40px;
        }
      }
    }
  }
}
@media screen and(max-width: 1440px) {
  .newsstand .content .left_bottom img{

    height: 112px;
  }
}
.phone {
  .content .left_bottom .title {
    line-height: 1.8rem;
    padding: 0.6rem 0;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .newsstand .right_bottom {
    /deep/ .first {
      height: auto;
    }
  }
}
</style>