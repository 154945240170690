<template>
  <div class="nav_box" :class="{ noFixed }">
    <div class="navigation">
      <ul class="nav mainWidth">
        <li
          :style="{ width: widthnum }"
          ref="lid"
          v-for="(item, index) in list"
          :key="index"
        >
          <router-link target="_blank" :to="item.route">{{
            item.text
          }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import api from "@/api/index.js";
import axios from "axios";
export default {
  name: "",

  components: {
    api,
    axios,
  },

  data() {
    return {
      list: [
        {
          text: "社科视频首页",
          route: "/",
        },
        {
          text: "资讯",
          route: { path: "/zixun" },
        },
        {
          text: "报刊视界",
          route: "/bksj",
        },
        {
          text: "学人",
          route: "/xueren",
        },
        {
          text: "访谈",
          route: { path: "/fangtan" },
        },
        {
          text: "专题",
          route: "/zhuanti",
        },
        {
          text: "我说",
          route: { path: "/woshuo" },
        },
        {
          text: "直播",
          route: "/zhibo",
        },
      ],
      widthnum: "",
      nav_drawer: true,
    };
  },
  created() {
    // console.log("1111", this.$store.state.PhoneNavShow);
    navlist: "";
  },

  methods: {
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
  },
  mounted() {
    var screenWidth = document.getElementById("app");
    // console.log(screenWidth)
    var box = ((screenWidth / 24) * 20 - 8 * 2 * 20) / 8;
    this.widthnum = this.$refs.lid.clientWidth;
    this.widthnum = box + "px";
    // console.log(this.widthnum)
  },
  computed: {
    noFixed() {
      return this.$route.path === "/index";
    },
  },
};
</script>

<style scoped lang="scss">
.nav_box {
  background: url("../assets/image/indexBanner.jpg");
  background-position: 100% 100%;
  position: fixed;
  top: 3.75rem;
  left: 0;
  right: 0;
  z-index: 1000;

  .navigation {
    width: 100%;
    z-index: 99;
    background: url("../assets/image/home/导航.png");
    background-size: 100% 100%;

    // .el-menu {
    //   .el-menu-item {
    //     padding: 0 4.0625rem;
    //     font-size: 1.25rem;
    //     color: #ffffff;
    //     min-width: 7.5rem;
    //     text-align: center;
    //     &:hover {
    //       background-color: transparent !important;
    //       color: #ff0000 !important;
    //     }
    //   }
    // }
    .nav {
      display: flex;
      justify-content: space-around;
      margin: auto;

      li {
        // padding: 1rem;
        font-size: 1.25rem;
        box-sizing: border-box;
      }

      li a:hover {
        font-weight: 700 !important;
      }
    }
  }
}
.nav_box.noFixed {
  position: static;
}
</style>