var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.list.length)?_c('div',{staticClass:"Book"},[_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',[_c('router-link',{attrs:{"target":"_blank","to":{ path: '/homeList', query: { id: 69 } }}},[_c('HomeHeadline',{attrs:{"title":require('@/assets/image/home/好书.png')}})],1),_c('div',{staticClass:"content"},[_c('el-row',[_c('el-col',[(this.equipment == 'pc')?_c('div',{staticClass:"right swiper-wrapper"},[_c('Swiper',{attrs:{"options":_vm.swiperOption}},_vm._l((_vm.list),function(item,index){return _c('SwiperSlide',{key:item.index,staticClass:"swiper-no-swiping"},[_c('div',{staticClass:"crad",attrs:{"align":"center"}},[_c('router-link',{attrs:{"target":"_blank","to":{
                        path: '/xq',
                        query: {
                          qing: item.id,
                          mian: 'shou_shu',
                        },
                      }}},[(index === 0 || index === _vm.list.length - 1)?_c('img',{attrs:{"src":item.pub_cover,"alt":""}}):_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.pub_cover),expression:"item.pub_cover"}],attrs:{"alt":""}}),_c('div',{staticClass:"crad_content"},[_c('p',[_vm._v(_vm._s(item.SYS_TOPIC))])])])],1)])}),1)],1):_vm._e(),(this.equipment == 'phone')?_c('div',{staticClass:"right_phone"},[_c('Swiper',{attrs:{"options":_vm.swiperOption_2}},_vm._l((_vm.list),function(item){return _c('SwiperSlide',{key:item.index},[_c('div',{staticClass:"crad",attrs:{"align":"center"}},[_c('router-link',{staticClass:"link",attrs:{"target":"_blank","to":{
                        path: '/xq',
                        query: {
                          qing: item.id,
                          mian: 'shou_shu',
                        },
                      }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.pub_cover),expression:"item.pub_cover"}],attrs:{"alt":""}}),_c('div',{staticClass:"crad_content"},[_c('p',[_vm._v(_vm._s(item.SYS_TOPIC))])])])],1)])}),1)],1):_vm._e()])],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }