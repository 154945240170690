<!-- 专题 -->
<template>
  <div class="Subject" v-if="shou_zhuan.length">
    <el-row type="flex" justify="center" >
      <el-col >
        <router-link target="_blank" :to="{ path: '/zhuanti' }">
          <HomeHeadline :title="require('@/assets/image/home/专题.png')" />
        </router-link>
      
        <div class="content">
          <div class="card_box" v-for="item in shou_zhuan" :key="item.index">
            <a :href="item.LINK_ADDRESS" target="_blank">
                <div class="crad">
                  <img  v-lazy="item.pub_cover" alt="" />
                  <hr />
                  <p>{{ item.SYS_TOPIC }}</p>
                </div>
              </a>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import HomeHeadline from "@/components/HomeHeadline.vue";
import api from '@/api/index.js'
export default {
  name: "",

  components: {
    HomeHeadline
  },

  data () {
    return {
      shou_zhuan: '',
    };
  },

  created () {
    api.post('spc/cms/publish/queryList.do', {
      s: 2,
      c: 65
    }).then((res) => {
      if(this.$store.state.isEquipment == 'pc'){
        this.shou_zhuan = res.result.slice(0, 5)
      }else{
        this.shou_zhuan = res.result.slice(0, 1)
      }
    })
  },

  methods: {},
  mounted () {
  },
};
</script>

<style lang='scss' scoped>
.Subject {
  background: url("../../assets/image/home/pic(7).png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .content {
    margin-top: 1rem;

    .card_box{
      width: 19%;
      display: inline-block;
      padding: 0 .5%;
    }
    .crad {
      cursor: pointer;
      padding: 1rem;
      background: #ffffff;
      box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.07);
      // margin-top: 1rem;

      img {
        width: 100%;
        aspect-ratio: 4/3;
        height: 176px;
        // height: 9.375rem;
        // height: 8.625rem;
      }
      @media screen and(max-width:1440px) {
        img{
          height: 122px;
        }
      }

      hr {
        width: 40%;
        border: 1px solid #465289;
        margin: 1rem auto;
      }

      p {
        height: 4rem;
        font-size: 1.25rem;
        font-weight: bold;
        color: #333333;
        line-height: 2rem;
        text-align: center;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
      }

      &:hover {
        background: #546092;

        hr {
          border: 1px solid #ffffff;
        }

        p {
          color: #ffffff;
        }
      }
    }
  }
}
</style>