<!-- 成果 -->
<template>
  <div class="Achievement" v-if="shou_c.length">
    <el-row type="flex" justify="center" >
      <el-col >
        <!-- 首页成果栏目跳转 -->
        <router-link target="_blank" :to="{ path: '/homeList', query: { id: 66 } }">
          <HomeHeadline :title="require('@/assets/image/home/成果.png')"/>
        </router-link>

        <div class="content">
          <el-row :gutter="$store.state.isEquipment == 'pc'?20:0">
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" v-for="a, b in shou_c" :key="b">
              <div class="card">
                <!-- lyd -->
            <router-link target="_blank" :to="{ path: '/xq', query: { qing: a.id,mian: 'shou_cheng' } }">
                <img  v-lazy="a.pub_cover" alt=""/>
                <p class="title">{{ a.SYS_TOPIC }}</p>
                <p :class="['text','textol']">{{ $AwayTell(a.DESCRIPTION) }}</p>
            </router-link>

              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import HomeHeadline from "@/components/HomeHeadline.vue";
import api from '@/api/index.js'
export default {
  components: {
    HomeHeadline,
  },
  props: {},
  data () {
    return {
      shou_c: '',
    };
  },
  computed: {},
  methods: {},
  created () {
    api.post('spc/cms/publish/queryList.do', {
      s: 2,
      c: 66
    }).then((res) => {
      if(this.$store.state.isEquipment == 'pc'){
        this.shou_c = res.result.slice(0, 4)
      }else{
        this.shou_c = res.result.slice(0, 1)
      }
      
    })
  },
};
</script>
<style lang="scss">
.Achievement {
  background: url("../../assets/image/home/pic(7).png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // padding: 1rem 0;

  .content {
    margin-top: 1rem;

    .card {
      cursor: pointer;
      padding: 1rem;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.07);
      background: #ffffff;
      // height: 25.875rem;
      // margin-top: 1rem;

      img {
        width: 100%;
        height: 227px;
        margin-bottom: 0.5rem;
      }

      .title {
        color: #212121;
        font-size: 1.25rem;
        font-weight: bold;
        line-height: 1.875rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        height: 3.75rem;
      }

      .text {
        font-size: 1rem;
        font-weight: 400;
        color: #707070!important;
        line-height: 1.75rem;
        margin-top: 0.5rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 12;
        height: 21rem;
        text-align: justify;

        // -webkit-line-clamp: 11;
        span{
        color: #707070!important;
        }
        &:hover {
          p,
          span {
            color: #ffffff !important;
          }
        }
      }

      .textol {
        font-size: 1rem;
        font-weight: 400;
        color: #707070!important;
        line-height: 1.75rem;
        margin-top: 0.5rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        height: 8.75rem;
        // -webkit-line-clamp: 4;
        span{
        color: #707070!important;
        }
      }

      &:hover {
        background: #546092;
        p,
        span {
          color: #ffffff!important;
        }
      }
    }
  }
}
@media screen and(max-width: 1440px) {
  .Achievement .content .card img{
    height: 155px;
  }
}
</style>