<template>
  <el-backtop><div></div></el-backtop>
</template>

<script>
export default {

  data() {
    return {};
  }
};
</script>
<style scoped lang="scss">
.el-backtop {
  width: 3.9rem;
  height: 3.9rem;
  background: url("~@/assets/image/sk_gotop_active.png") no-repeat center
    center/cover;
}
</style>