<!-- 资讯 -->
<template>
  <div class="Information">
    <el-row type="flex" justify="center">
      <el-col>
        <!-- 首页咨询跳转 -->
        <router-link target="_blank" :to="{ path: '/zixun' }">
          <HomeHeadline :title="require('@/assets/image/home/矩形.png')" />
        </router-link>

        <div class="content">
          <router-link
            target="_blank"
            v-for="item in zixun_list"
            :key="item.index"
            class="normal"
            :to="{
              path: '/xq',
              query: {
                qing: item.id,
                mian: 'shou_zi',
              },
            }"
          >
            <div class="content_imgtext">
              <div class="content_img">
                <img v-lazy="item.pub_cover" alt="" />
              </div>
              <div class="content_text">
                <p>{{ item.SYS_TOPIC }}</p>
              </div>
            </div>
          </router-link>
          <div class="special">
            <div class="content_list">
              <ul>
                <router-link
                  target="_blank"
                  :to="{
                    path: '/xq',
                    query: {
                      qing: item.id,
                      mian: 'shou_zi',
                    },
                  }"
                  v-for="(item, b) in zixun_wen"
                  :key="item.index"
                >
                  <li :class="b == 0 ? 'firstli' : ''">{{ item.SYS_TOPIC }}</li>
                </router-link>
              </ul>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import HomeHeadline from "@/components/HomeHeadline.vue";
import api from "@/api/index.js";
export default {
  name: "",

  components: {
    HomeHeadline,
  },

  data() {
    return {
      zixun_list: "",
      zixun_wen: "",
    };
  },

  created() {},

  methods: {},
  mounted() {
    api
      .post("spc/cms/publish/queryList.do", {
        s: 2,
        c: 50,
      })
      .then((res) => {
        if (this.$store.state.isEquipment == "pc") {
          // 截取前三个展示图片和文字
          this.zixun_list = res.result.slice(0, 3);
          // 文字部分展示数据3个以后的
          this.zixun_wen = res.result.slice(3, 9);
        } else {
          // 截取前三个展示图片和文字
          this.zixun_list = res.result.slice(0, 1);
          // 文字部分展示数据3个以后的
          this.zixun_wen = res.result.slice(1, 4);
        }
      });
  },
};
</script>

<style scoped lang="scss">
.Information {
  // margin: 1rem 0rem;
  background: url("../../assets/image/home/pic(7).png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 443px;

  .content {
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;
    font-size: 1.2rem;

    .normal {
      width: 20%;
    }

    .special {
      width: 37.5%;
    }

    .content_imgtext {
      cursor: pointer;

      .content_img {
        width: 100%;

        img {
          width: 100%;
          aspect-ratio: 4/3;
          display: block;
          height: 210px;
        }
      }

      .content_text {
        width: 100%;
        cursor: pointer;

        p {
          color: #000000;
          line-height: 1.875rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        p:hover {
          color: #277398;
        }
      }
    }

    .content_list {
      // width: 100%;
      background: #ffffff;
      padding: 0.5rem 1rem 0;

      ul {
        .firstli {
          font-weight: bold;
        }

        li {
          color: #000000;
          line-height: 2.4rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        li::before {
          content: "\2022 ";
          font-size: 1.25rem;
          color: #4a568b;
          margin-right: 0.3125rem;
        }

        li:hover {
          color: #277398;
          // font-weight: 400;
        }
      }
    }
  }
}

@media screen and(max-width:1440px) {
  .Information {
    height: 350px;
    .content .content_list ul li {
      line-height: 2rem;
    }
  }
  .Information .content .content_imgtext .content_img img{
    height:150px;
  }
}
.phone .Information {
  min-height: 400px;
  height: auto;
  .content {
    flex-wrap: wrap;
    .content_list ul li {
      line-height: 40px;
    }
  }
  .normal {
    width: 100%;
  }
  .special {
    width: 100%;
  }
  .content .content_list ul .firstli {
    font-weight: normal;
  }
  .content .content_imgtext .content_img img{
    height:auto;
  }
}
</style>