<template>
  <div class="Footer" :class="{indexFooter:$route.name==='index',phone:$store.state.isEquipment == 'phone'}">
    <el-row type="flex" justify="center">
      <el-col >
        <div class="links">
          <a href="http://www.cssn.cn/gywm/" target="_blank" ignoreapd="true"
            >关于我们</a
          >
          <a href="http://www.cssn.cn/ggfw/" target="_blank" ignoreapd="true"
            >广告服务</a
          >
          <a href="http://www.cssn.cn/wzsm/" target="_blank" ignoreapd="true"
            >网站声明</a
          >
          <a href="http://www.cssn.cn/wzjc/" target="_blank" ignoreapd="true"
            >网站纠错</a
          >
          <a href="http://www.cssn.cn/lxwm/" target="_blank" ignoreapd="true"
            >联系我们</a
          >
        </div>
        <div class="icp">
          <span>举报电话：010-65393398</span>
          <span>举报邮箱：zgshkxw_cssn@163.com</span>
          <span>互联网新闻信息服务许可证：10120220003</span>
          <span
            ><a
              target="_blank"
              href="https://beian.miit.gov.cn/"
              ignoreapd="true"
              >京ICP备11013869号</a
            ></span
          >
          <span
            ><a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502030146"
              ignoreapd="true"
              >京公网安备11010502030146号</a
            ></span
          >
        </div>
        <div class="icp icp_sscp">
          <ul>
            <li>
              <div class="sscp">
                <span class="sk_sscp_logo"
                  ><img src="@/assets/image/home/sscp.png" alt=""
                /></span>
                <span class="text"
                  >中国社会科学杂志社版权所有，未经书面授权禁止使用</span
                >
              </div>
            </li>
            <li>
              <span
                >Copyright © 2011-{{ year }} by www.cssn.cn all rights reserved</span
              >
              <!-- <a class="kexin" href="https://ss.knet.cn/verifyseal.dll?sn=a13101211010042869jlua000000&pa=0.6747529413469211" target="_blank">
                <img
                  src="https://rr.knet.cn/static/images/newLogo/star5.png"
                  alt="可信网站"
                  class="kexin_img_1"
                />
                <img src="https://rr.knet.cn/static/images/newLogo/9Y8M.png" alt="可信网站" class="kexin_img_2" />
              </a> -->
            </li>
          </ul>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
//import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  data() {
    //这里存放数据
    return {
			year:new Date().getFullYear()
		};
  },
  //计算属性 类似于data概念
  computed: {},
  //方法集合
  methods: {},
  created(){
    console.log(this.$route)
  }
};
</script>
<style scoped lang="scss">

.Footer {
  font-size: 1.125rem;
  color:#212121;
  width: 100%;
  border-top: 0.375rem solid #2181b0;
  min-height: 6.25rem;
  padding: 0.625rem 0;
  margin-top: 2rem;
  a {
    color: inherit;
    text-decoration: none;
  }
  .links {
    padding: 0.625rem 0;
    text-align: center;
    margin-left: 4%;
    a {
      margin: 0.5rem 1.5rem;
      position: relative;
      display: inline-block;
    }
    a:after {
      content: "";
      width: 0.0625rem;
      height: 1rem;
      display: block;
      position: absolute;
      right: -1.5rem;
      top: 0;
      bottom:0;
      margin:auto;
      background: #999;
    }
    a:last-child:after {
      display: none;
    }
  }
  .icp {
    margin-bottom: 0.625rem;
    text-align: center;
    span {
      display: inline-block;
      margin: 0 0.625rem;
      line-height: 2rem;
    }
  }
  .icp_sscp {
    ul {
      li {
        display: inline-block;
      }
    }
    .sk_sscp_logo {
      left: 6.875rem;
    }
    span:nth-child(2) {
      margin-right: 1.25rem;
    }
    img {
      vertical-align: middle;
    }
    .kexin {
      position: relative;
      height: 33px;
      display: inline-block;
      width: 92px;
      vertical-align:middle;
      img {
        width:100%;
        height:100%;
        position: absolute;
        left:0;
      }

      .kexin_img_1 {
        opacity: 0;
        animation: mymove 7s infinite;
      }
      .kexin_img_2 {
        opacity: 0;
        animation: mymove 7s 3.5s infinite;
      }

      /* Standard syntax */
      @keyframes mymove {
        0%{
          opacity:0;
        }
        12.5% {
          opacity: 1;
        }
        50%{
          opacity:1;
        }
        62.5%{
          opacity:0;
        }
      }

      /* Chrome, Safari, Opera */
      @-webkit-keyframes mymove2 {
        50% {
          opacity: 0;
        }
      }

      /* Standard syntax */
      @keyframes mymove2 {
        50% {
          opacity: 0;
        }
      }
    }
  }
}
.indexFooter{
  border-color:transparent;
  background:url(~@/assets/image/footerBg.png) no-repeat center center/100% 100%;
  color:#fff;
}
.Footer.phone{
  .links{
    a:after{
      display:none;
    }
  }
}
</style>