import axios from 'axios'
const baseurl = 'https://v.cssn.cn/portal/api'
export default {
  get(url, params) {
    return axios.get(`${baseurl}/${url}`, { params })
      .then(response => response.data)
  },
  post(url, data) {
    if (url === 'spc/cms/publish/queryList.do' && !data.sorts && data.c) {
      data.sorts = ["pub_a_order asc", "pub_lastmodified desc", "id asc"];
    }
    return axios.post(`${baseurl}/${url}`, data)
      .then(response => response.data)
  },
}
// baseurl : http://v.cssn.cn/portal/api/spc/cms/publish/ 改成 http://v.cssn.cn/portal/api
// queryList.do 改成 spc/cms/publish/queryList.do