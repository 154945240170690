<!-- 学人 -->
<template>
  <div>
    <div
      class="Scholar"
      :class="{ phone: $store.state.isEquipment == 'phone' }"
      v-show="list1.length"
    >
      <el-row type="flex" justify="center">
        <el-col>
          <div class="title" v-if="$store.state.isEquipment === 'pc'">
            <router-link target="_blank" to="/xueren">
              <img
                src="@/assets/image/home/学人.png"
                alt=""
                style="height: 100%"
              />
            </router-link>
            <div class="tabs">
              <div
                :class="['tabs_2', b == tount ? 'tabs_1' : '']"
                @mouseenter="stab_o(b)"
                @click="routship(b)"
                v-for="(a, b) in tab_q"
                :key="b"
              >
                <div>
                  <span>{{ a }}</span>
                </div>
                <div class="tip_bottom" v-show="b == tount"></div>
              </div>
            </div>
            <div class="right">
              <img src="@/assets/image/home/右.png" alt="" />
            </div>
          </div>
          <div v-else class="Scholar_phone">
            <router-link target="_blank" to="/xueren">
              <HomeHeadline :title="require('@/assets/image/home/学人.png')" />
            </router-link>
            <div class="title">
              <div class="tabs">
                <div
                  :class="['tabs_2', b == tount ? 'tabs_1' : '']"
                  @click="stab_o(b)"
                  v-for="(a, b) in tab_q"
                  :key="b"
                >
                  <div>
                    <span>{{ a }}</span>
                  </div>
                  <div class="tip_bottom" v-show="b == tount"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <XueRen1 v-if="tount === 0" :list="list1"></XueRen1>
            <XueRen2 v-if="tount === 1" :list2="list2"></XueRen2>
            <XueRen3 v-if="tount === 2" :list="list3"></XueRen3>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- 手机端 -->
    <!-- <div
      class="Scholar_phone"
      v-if="this.$store.state.isEquipment == 'phone' ? true : false"
    >
      <router-link target="_blank" to="/xueren">
        <HomeHeadline :title="require('@/assets/image/home/学人.png')" />
      </router-link>
      <div class="title">
        <div class="tabs">
          <div
            :class="['tabs_2', b == tount ? 'tabs_1' : '']"
            @click="stab_o(b)"
            v-for="(a, b) in tab_q"
            :key="b"
          >
            <div>
              <span>{{ a }}</span>
            </div>
            <div class="tip_bottom" v-show="b == tount"></div>
          </div>
        </div>
      </div>

      <el-row>
        <el-col :span="24">
          <div class="content">
            <Swiper :options="swiperOption_2">
              <SwiperSlide v-for="item in xue_lun" :key="item.index">
                <div align="center" class="crad_xueshu" v-if="tount == 1">
                  <div class="xueshu_box">
                    <img  v-lazy="item.pub_cover" alt="" />
                    <p class="name">{{ item.KEYWORDS }}</p>
                    <p class="title1">{{ item.SYS_TOPIC }}</p>
                    <p class="text">{{ $AwayTell(item.DESCRIPTION) }}</p>
                  </div>
                </div>

                <div align="center" class="cradtwo" v-if="tount == 0">
                  <router-link
                    target="_blank"
                    :to="{
                      path: '/xq',
                      query: {
                        key: item.KEYWORDS,
                        qing: item.id,
                        xiang: item.doclibId,
                        xiangdoc: item.docId,
                        menid: item.SYS_DOCUMENTID,
                        libid: item.SYS_DOCLIBID,
                        mian: `shx${tount}`,
                      },
                    }"
                  >
                    <img  v-lazy="item.pub_cover" alt="" />
                    <div class="oop">{{ item.SYS_TOPIC }}</div>
                  </router-link>
                </div>

                <div align="center" class="cradtwo" v-if="tount == 2">
                  <router-link
                    target="_blank"
                    :to="{
                      path: '/xq',
                      query: {
                        key: item.KEYWORDS,
                        qing: item.id,
                        xiang: item.doclibId,
                        xiangdoc: item.docId,
                        menid: item.SYS_DOCUMENTID,
                        libid: item.SYS_DOCLIBID,
                        mian: `shx${tount}`,
                      },
                    }"
                  >
                    <div align="center" class="cradyin">
                      <div class="crad_content">
                        <div class="senc">
                          <div class="smil_s">
                            <div class="Image">
                              <img  v-lazy="item.pub_cover" alt="" />
                            </div>
                            <div class="Spacez">
                              <hr />
                              <div class="im_rigzi">
                                <span class="title" :title="item.FUBIAOTI">{{
                                  item.FUBIAOTI
                                }}</span>
                                <div class="rimg_bot">
                                  {{ $AwayTell(item.DESCRIPTION, 0, 30) }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </SwiperSlide>
              <div class="swiper-pagination" slot="pagination"></div>
            </Swiper>
          </div>
        </el-col>
      </el-row>
    </div> -->
  </div>
</template>

<script>
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import "swiper/css/swiper.css";
import HomeHeadline from "@/components/HomeHeadline.vue";
import XueRen1 from "../../components/xueren/XueRen1.vue";
import XueRen2 from "../../components/xueren/XueRen2.vue";
import XueRen3 from "../../components/xueren/XueRen3.vue";
import api from "@/api/index.js";
export default {
  components: {
    // Swiper,
    // SwiperSlide,
    HomeHeadline,
    XueRen1,
    XueRen2,
    XueRen3,
  },
  props: {},
  data() {
    return {
      xue_lun: "",
      tab_q: ["共和国学人", "大家印象", "学术百家"],
      swiperOption: {
        // slidesPerGroup: 3,
        // initialSlide: 3,
        loop: true,
        paginationClickable: true,
        EffectCards: true,
        slidesPerView: this.$store.state.smallPc ? 2 : 1,
        spaceBetween: 20,
        navigation: {
          nextEl: ".swiper-button-prev",
          prevEl: ".swiper-button-next",
        },
      },
      swiperOption_3: {
        // slidesPerGroup: 3,
        // initialSlide: 3,
        loop: true,

        EffectCards: true,
        slidesPerView: this.$store.state.smallPc ? 2 : 1,
        spaceBetween: 20,
        pagination: { el: "#swiper-pagination3", clickable: true }, // 分页按钮
      },

      swiperOption_2: {
        // autoplay: {
        //   disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay
        //   delay: 3000, // 自动切换的时间间隔（单位ms）
        // },
        loop: true,
        slidesPerView: 3,
        slidesPerGroup: 3,
        navigation: {
          nextEl: ".swiper-button-prev1",
          prevEl: ".swiper-button-next1",
        },
        spaceBetween: 40,
      },
      list1: [],
      list2: [],
      list3: [],
      tount: 0,
    };
  },
  methods: {
    routship(b) {
      let id = 86;
      if (b == 2) {
        id = 87;
      } else if (b == 1) {
        id = 72;
      }
      let routerJump = this.$router.resolve({
        path: "/xuerenlist",
        query: { id },
      });
      window.open(routerJump.href, "_blank");
    },
    getList1() {
      return api
        .post("spc/cms/publish/queryList.do", {
          s: 2,
          c: 62,
        })
        .then((res) => {
          this.list1 = res.result;
        });
    },
    getList2() {
      api
        .post("spc/cms/publish/queryList.do", {
          s: 2,
          c: 64,
        })
        .then((res) => {
          this.list2 = res.result;
        });
    },
    getList3() {
      api
        .post("spc/cms/publish/queryList.do", {
          s: 2,
          c: 63, //63
        })
        .then((res) => {
          this.list3 = res.result;
        });
    },
    stab_o(fagg) {
      this.tount = fagg;
    },
  },
  created() {
    this.getList1();
    this.getList2();
    this.getList3();
  },
};
</script>
<style scoped lang="scss">
.Scholar {
  // margin: 3.3rem 0 0 0!important;
  background: url("../../assets/image/home/pic(7).png");
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  margin: auto;
  min-height: 809px;

  .title {
    overflow: hidden;
    margin: 4rem 0 2rem;
    img {
      float: left;
      // width: 7rem;
      margin-right: 0.8125rem;
    }

    .tabs {
      padding-top: 0.3rem;

      .tabs_2 {
        transform: skewX(16deg);
        padding: 1rem 1.5rem;
        float: left;
        // background: #465289;
        background: #737eb1;
        cursor: pointer;
        margin-top: 0.2rem;
        div {
          transform: skewX(-16deg);

          span {
            font-weight: bold;
            color: #ffffff;
            font-size: 1.5rem;
            line-height: 1.5rem;
            opacity: 0.3;
          }
        }
      }

      .tabs_1 {
        background: #465289;
        color: #ffffff !important;
        position: relative;

        div {
          span {
            opacity: 1 !important;
          }
        }
      }

      .tip_bottom {
        position: absolute;
        bottom: -0.5625rem;
        left: 39%;
        width: 0;
        height: 0;
        border-left: 0.9375rem solid transparent;
        border-right: 0.9375rem solid transparent;
        border-top: 0.9375rem solid #465289;
      }
    }

    .right {
      width: 34%;
      height: 4.375rem;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;

      // padding-left: 6rem;
      img {
        padding-left: 3rem;
        width: 100%;
      }
    }
  }

  .content {
    // margin-top: 3.875rem;
    .swiper-container {
      padding: 0 0 4rem 0;
    }
    .cradtwo {
      width: 20.9375rem;
      height: 100%;
      background-color: #8a8a8a;
      box-shadow: 0.4rem 0.4rem 1rem 0px rgba(0, 0, 0, 0.07);
      padding: 0.9375rem 0.9375rem 0.5rem 0.9375rem;
      box-sizing: border-box;
      img {
        width: 100%;
        // height:auto;
        // aspect-ratio: 3/4;
        // height: 25.4375rem;
      }
      .oop {
        font-size: 1.125rem;
        line-height: 1.75rem;
        height: 3.5rem;
        margin-top: 0.1875rem;
        color: #000000;
      }
    }
    .crad {
      // width: 442px;
      // height: 622px;

      box-shadow: 2px 2px 1rem 0px rgba(0, 0, 0, 0.2);
      padding: 1rem;
      display: flex;
      border-radius: 0.625rem;
      color: #333;

      img {
        margin-right: 1.875rem;
      }

      .name {
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 1.5rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        padding: 0.38rem 0 1rem;
      }

      .title1 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        font-size: 1.13rem;
        line-height: 1.88rem;
        height: 3.76rem;
      }

      .text {
        font-size: 1rem;
        color: #666666;
        line-height: 1.75rem;
        margin-top: 1rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        max-height: 8.75rem;
        text-align: justify;
      }
    }

    .arrows {
      position: absolute;
      bottom: 1.25rem;
      left: 50%;

      img {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }
  .content-first {
    .crad {
      background-image: url("../../assets/image/home/蓝色次背景.png");
      background-size: 100%;
      background-repeat: no-repeat;
      img {
        width: 16.25rem;
        height: 16.25rem;
        border-radius: 50%;
      }
    }
  }
  .content-second {
    position: relative;
    padding-bottom: 0;
    .arrows {
      position: absolute;
      display: flex;
      justify-content: space-between;
      left: -4rem;
      right: -4rem;
      top: 50%;
      bottom: auto;
      margin-top: -1.25rem;
    }
    .crad {
      display: block;
      img {
        width: 100%;
        height: auto;
        border-radius: 0;
      }
      .title1 {
        height: 5.64rem;
      }
    }
    .swiper-container {
      padding-bottom: 0;
    }
  }
  .content-third {
    img {
      width: 14rem;
    }
  }

  .left_shade {
    width: 10rem;
    height: 32rem;
    background: linear-gradient(to right, #ffffff, transparent);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
  }

  .right_shade {
    width: 9rem;
    height: 35rem;
    background: linear-gradient(to left, #ffffff, transparent);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
  }
}
.Scholar.phone {
  width: auto;
  min-height: auto;
}

.Scholar .Scholar_phone {
  .title {
    margin: 1rem auto 0 auto;
  }
  .tabs {
    text-align: center;
    overflow: hidden;
    .tabs_2 {
      transform: skewX(16deg);
      // background: #465289;
      background: #737eb1;
      padding: 0.4rem 0;
      text-align: center;
      box-sizing: border-box;
      width: 32%;
      font-size: 0.64rem;
      position: relative;
      line-height: 1rem;
      margin: 0;
      div {
        transform: skewX(-16deg);

        span {
          color: #ffffff;
          font-weight: bold;
          font-size: 1.2rem;
          opacity: 0.3;
        }
      }
    }

    .tabs_1 {
      background: #465289;
      color: #ffffff !important;
      position: relative;

      div {
        span {
          color: #ffffff;
          opacity: 1;
        }
      }
    }

    .tip_bottom {
      position: absolute;
      bottom: -0.5625rem;
      left: 30%;
      width: 0;
      height: 0;
      border-left: 0.9375rem solid transparent;
      border-right: 0.9375rem solid transparent;
      border-top: 0.9375rem solid #465289;
    }
  }

  .content {
    margin-top: 1rem;

    .swiper-pagination {
      position: static;
      margin-top: 1rem;
    }

    img {
      width: 100%;
      // height: auto;
      // aspect-ratio: 3/4
      // height: 30.125rem;
    }
    .crad {
      padding: 0.5rem 1rem;

      .title1 {
        margin-top: 1rem;
        font-size: 1rem;
      }
    }
    .cradtwo {
      padding: 0.5rem 1rem;
    }

    .crad_xueshu {
      padding: 0 1rem;
      .xueshu_box {
        background-color: #ffffff;
        background-image: url("../../assets/image/home/蓝色次背景.png");
        background-size: 100% auto;
        background-repeat: no-repeat;
        box-shadow: 0px 9px 40px 0px rgba(0, 0, 0, 0.07);
        padding: 6rem 1rem 2rem 1rem;

        img {
          margin-bottom: 1.5rem;
          width: 10rem;
          height: 10rem;
          border-radius: 50%;
        }

        p {
          text-align: center;
        }

        .name {
          font-size: 1.5rem;
          font-weight: bold;
          line-height: 1.5rem;
          margin-bottom: 0.625rem;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }

        .title1 {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          width: 14.63rem;
          font-size: 1.13rem;
          color: #333333;
          line-height: 1.88rem;
          height: 3.76rem;
        }

        .text {
          height: 5rem;
          font-size: 1rem;
          font-weight: 400;
          color: #666666;
          line-height: 1.75rem;
          margin-top: 1rem;
          max-height: 8.75rem;
          // overflow: hidden;
          // display: -webkit-box;
          // -webkit-box-orient: vertical;
          // -webkit-line-clamp: 5;
        }
      }
    }
  }
  .cradyin {
    .crad_content {
      .smil_s {
        .Spacez {
          hr {
            margin: 1rem 0 0.5rem 0;
          }
        }
      }
    }
  }
}
a {
  color: #000000 !important;
}
.cradyin {
  // padding-bottom: 1.875rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .crad_content {
    height: 30rem;
    box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.07);
    .senc {
      height: 100%;
    }
    .smil_s {
      padding-top: 2.5rem;
      // padding: .875rem 1rem 1rem 1rem;
      box-sizing: border-box;
      cursor: pointer;
      height: 100%;
      background-color: #465289;

      .Image {
        // width: 100%;
        // height: 17.5rem;

        // background-color: #000;
        img {
          // width: 13.125rem;
          // height: 17.5rem;
          -webkit-filter: drop-shadow(
            8px 8px 1px rgba(0, 0, 0, 1)
          ); /*考虑浏览器兼容性：兼容 Chrome, Safari, Opera */
          filter: drop-shadow(8px 8px 1px rgba(0, 0, 0, 1));
          width: 80%;
          height: auto;
          aspect-ratio: 4/3;
        }
      }

      .Spacez {
        padding: 1rem 0 0 0;
        position: relative;
        hr {
          width: 80%;
          border: 1px solid #fff;
          margin: 1rem 0 1.5rem 0;
        }
        .jaintou {
          width: 0.5625rem;
          height: 0.5625rem;
          position: absolute;
          top: 1.6rem;
          left: 0;
          // margin: 10px 11px;
        }

        .im_rigzi {
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0 2rem 0 2rem;
          a {
            color: #000033 !important;
          }

          .title {
            font-size: 1.375rem;
            font-weight: 700;
            line-height: 2rem;
          }

          .rimg_bot {
            // width: 241px;
            margin-top: 1rem;
            font-size: 1.375rem;
            line-height: 2rem;
            // display: -webkit-box;
            // overflow: hidden;
            // white-space: normal !important;
            // text-overflow: ellipsis;
            // word-wrap: break-word;
            // -webkit-line-clamp: 1;
            // -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}
.page {
  display: flex;
}
</style>
