var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content content-third"},[(_vm.$store.state.isEquipment === 'pc')?_c('div',[(_vm.list3.length)?_c('Swiper',{attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.list3),function(page,index){return _c('SwiperSlide',{key:index,staticClass:"page"},[_c('el-row',{attrs:{"gutter":20}},_vm._l((page),function(item,index){return _c('el-col',{key:item.id,staticClass:"singleItem",class:'singleItem' + index,attrs:{"span":12}},[_c('router-link',{staticClass:"crad",attrs:{"target":"_blank","to":{
              path: '/xq',
              query: {
                qing: item.id,
                mian: `shx${0}`,
              },
            }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.pub_cover),expression:"item.pub_cover"}],attrs:{"alt":""}}),_c('div',{staticClass:"imgRightBox"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(item.FUBIAOTI || '...'))]),_c('p',{staticClass:"title1"},[_vm._v(_vm._s(item.SYS_TOPIC))]),_c('p',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$AwayTell(item.DESCRIPTION))+" ")])])])],1)}),1)],1)}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2):_vm._e()],1):_c('div',{staticClass:"phone"},[(_vm.list3.length)?_c('Swiper',{attrs:{"options":_vm.swiperOption_phone}},_vm._l((_vm.list),function(item,index){return _c('SwiperSlide',{key:index},[_c('router-link',{staticClass:"crad",attrs:{"target":"_blank","to":{
          path: '/xq',
          query: {
            qing: item.id,
            mian: `shx${0}`,
          },
        }}},[(index == 0 || index == (_vm.list3.length - 1))?_c('img',{attrs:{"src":item.pub_cover}}):_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.pub_cover),expression:"item.pub_cover"}],attrs:{"alt":""}}),_c('div',[_c('p',{staticClass:"name"},[_vm._v(_vm._s(item.FUBIAOTI))]),_c('p',{staticClass:"title1"},[_vm._v(_vm._s(item.SYS_TOPIC))]),_c('p',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$AwayTell(item.DESCRIPTION))+" ")])])])],1)}),1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }