var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.shou_jiang.length)?_c('div',{staticClass:"Bema"},[_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',[_c('router-link',{attrs:{"target":"_blank","to":{ path: '/homeList', query: { id: 67 } }}},[_c('HomeHeadline',{attrs:{"title":require('@/assets/image/home/讲坛.png')}})],1),_c('div',{staticClass:"content"},[_c('el-row',{attrs:{"gutter":_vm.$store.state.isEquipment == 'pc' ? 20 : 0}},_vm._l((_vm.shou_jiang),function(item){return _c('el-col',{key:item.index,attrs:{"xs":24,"sm":12,"md":12,"lg":12,"xl":12}},[_c('div',{staticClass:"crad"},[_c('div',{staticClass:"top"},[_c('el-row',{attrs:{"gutter":_vm.$store.state.isEquipment == 'pc' ? 14 : 0}},[_c('router-link',{attrs:{"target":"_blank","to":{
                      path: '/xq',
                      query: {
                        qing: item.id,
                        mian: 'shou_jiang',
                      },
                    }}},[_c('el-col',{attrs:{"xs":24,"sm":10,"md":10,"lg":10,"xl":10}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.pub_cover),expression:"item.pub_cover"}],attrs:{"alt":""}})]),_c('el-col',{attrs:{"xs":24,"sm":10,"md":14,"lg":14,"xl":14}},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.SYS_TOPIC))]),_c('p',{staticClass:"text",domProps:{"innerHTML":_vm._s(item.DESCRIPTION)}})])],1)],1)],1)])])}),1),_c('el-row',{staticClass:"bottom",attrs:{"gutter":_vm.$store.state.isEquipment == 'pc' ? 20 : 0}},_vm._l((_vm.list),function(item){return _c('el-col',{key:item.index,attrs:{"xs":24,"sm":12,"md":12,"lg":12,"xl":12}},[_c('router-link',{attrs:{"target":"_blank","to":{
                path: '/xq',
                query: {
                  qing: item.id,
                  mian: 'shou_jiang',
                },
              }}},[_c('p',[_vm._v(_vm._s(item.SYS_TOPIC))])])],1)}),1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }