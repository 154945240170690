<template>
  <div class="content content-second">
    <Swiper
      :options="
        $store.state.isEquipment === 'pc' ? swiperOption : swiperOption_phone
      "
      :class="$store.state.isEquipment === 'pc' ? '' : 'phone'"
      v-if="list2.length"
    >
      <SwiperSlide v-for="(item, index) in list2" :key="index" class="page">
        <router-link
          class="crad"
          target="_blank"
          :to="{
            path: '/xq',
            query: {
              qing: item.id,
              mian: `shx${2}`,
            },
          }"
        >
          <img :src="item.pub_cover">
          <div>
            <p class="name">{{ item.FUBIAOTI }}</p>
            <p class="title1">{{ item.SYS_TOPIC }}</p>
            <p class="text">
              {{ $AwayTell(item.DESCRIPTION) }}
            </p>
            <img src="./more.png" alt="" class="more" />
          </div>
        </router-link>
      </SwiperSlide>
    </Swiper>
    <div class="arrows" v-if="$store.state.isEquipment === 'pc'">
      <img
        src="@/assets/image/home/arrow_left.png"
        alt=""
        class="swiper-button-next1"
      />
      <img
        src="@/assets/image/home/arrow_left.png"
        alt=""
        class="swiper-button-prev1"
        style="transform: rotateZ(180deg)"
      />
    </div>
  </div>
</template>

<script>
import api from "@/api/index.js";
export default {
  data() {
    return {
      swiperOption: {
        loop: true,
        EffectCards: true,
        slidesPerView: this.$store.state.isSmallPc?2:3,
        slidesPerGroup: this.$store.state.isSmallPc?2:3,
        navigation: {
          nextEl: ".swiper-button-prev1",
          prevEl: ".swiper-button-next1",
        },
        spaceBetween: 40,
      },
      swiperOption_phone: {
        loop: true,
        EffectCards: true,
        slidesPerView: 1,
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false,
        // },
      },
    };
  },
  props:['list2'],
  methods: {
    getList2() {
      api
        .post("spc/cms/publish/queryList.do", {
          s: 2,
          c: this.$route.name==='index'?64:72,
        })
        .then((res) => {
          this.list2 = res.result;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./common.scss";
.content-second {
  position: relative;
  padding-bottom: 0;
  .arrows {
    position: absolute;
    display: flex;
    justify-content: space-between;
    left: -3rem;
    right: -3rem;
    top: 50%;
    bottom: auto;
    margin-top: -1.25rem;
  }
  .crad {
    display: block !important;
    background-color: #fff;
    box-shadow: 0px 0px 1rem 0px rgba(107, 151, 182, 0.3);
    width: 100%;
    box-sizing: border-box;
    > img {
      width: 100%;
      height:294px;
      border-radius: 0;
      vertical-align: top;
    }
    .more {
      width: 4.5rem;
      margin-top: 1.85rem;
    }
    .title1 {
      // height: 5.64rem;
    }
    .text {
      color: #707070;
      height: 10.5rem;
      max-height: none;
    }
  }
  .swiper-container {
    padding-bottom: 0;
  }
  .phone {
    .crad {
      .text {
        color: #707070;
        -webkit-line-clamp: 7;
        height: 12.25rem;
        max-height: none;
      }
      .more {
        width: 20%;
        margin-top: 2.1rem;
      }
    }
  }
  @media screen and(max-width:1440px) {
    .crad > img{
      height:329px;
    }
  }
}
</style>