var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.VList.length)?_c('div',{staticClass:"Visual"},[_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',[_c('div',{staticClass:"head"},[_c('div',{staticClass:"headl"},[_c('img',{attrs:{"src":require("../assets/image/line.png"),"alt":""}}),_c('div',{staticClass:"round"})]),_c('div',{staticClass:"cent",on:{"click":function($event){return _vm.shipin_s(49)}}},[_c('img',{attrs:{"src":require("../assets/image/home/视评.png"),"alt":""}}),_c('span',[_vm._v("视评")])]),_c('div',{staticClass:"headl"},[_c('div',{staticClass:"round"}),_c('img',{attrs:{"src":require("../assets/image/line.png"),"alt":""}})])]),_c('div',{staticClass:"Visual_card"},[_c('el-row',{attrs:{"gutter":_vm.$store.state.isEquipment == 'pc'?20:0}},_vm._l((_vm.VList),function(item){return _c('el-col',{key:item.index,attrs:{"xs":24,"sm":8,"md":8,"lg":8,"xl":8}},[_c('router-link',{attrs:{"target":"_blank","to":{
                path: '/xq',
                query: {
                  qing: item.id,
                  mian: 'shi',
                },
              }}},[_c('div',{staticClass:"card"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.pub_cover),expression:"item.pub_cover"}],attrs:{"alt":""}}),_c('div',{staticClass:"card_content"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.SYS_TOPIC))]),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.$AwayTell(item.DESCRIPTION)))])])])])],1)}),1)],1)])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }