var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.shou_j.length)?_c('div',{staticClass:"Reality"},[_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',[_c('router-link',{attrs:{"target":"_blank","to":{ path: '/homeList', query: { id: 68 } }}},[_c('HomeHeadline',{attrs:{"title":require('@/assets/image/home/纪实.png')}})],1),_c('div',{staticClass:"content"},[_c('el-row',{attrs:{"gutter":_vm.$store.state.isEquipment == 'pc' ? 12 : 0}},[_c('el-col',{attrs:{"xs":24,"sm":10,"md":10,"lg":10,"xl":10}},[_c('div',{staticClass:"slideshow"},[_c('Swiper',{attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.shou_j),function(item,index){return _c('SwiperSlide',{key:item.index},[_c('div',{staticClass:"crad"},[_c('router-link',{attrs:{"target":"_blank","to":{
                      path: '/xq',
                      query: {
                        qing: item.id,
                        mian: 'shou_ji',
                      },
                    }}},[(index == 0 || index == (_vm.shou_j.length - 1))?_c('img',{attrs:{"src":item.pub_cover}}):_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.pub_cover),expression:"item.pub_cover"}],key:item.pub_cover,attrs:{"alt":""}}),_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.SYS_TOPIC))])])],1)])}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2)],1)]),(_vm.$store.state.isEquipment == 'pc')?_c('el-col',{attrs:{"xs":24,"sm":8,"md":8,"lg":8,"xl":8}},[_c('div',{staticClass:"middle"},[_vm._l((_vm.first_s),function(a){return _c('div',{key:a.index,staticClass:"middle_top"},[_c('router-link',{attrs:{"target":"_blank","to":{
                  path: '/xq',
                  query: {
                    qing: a.id,
                    mian: 'shou_ji',
                  },
                }}},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(a.SYS_TOPIC))]),_c('p',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$AwayTell(a.DESCRIPTION, 0, 76))+" "),_c('span',{staticClass:"sppa1"},[_vm._v("[详细]")])])])],1)}),_c('div',{staticClass:"middle_bottom"},[_c('ul',_vm._l((_vm.shengyu),function(item){return _c('li',{key:item.index},[_c('router-link',{attrs:{"target":"_blank","to":{
                      path: '/xq',
                      query: {
                        qing: item.id,
                        mian: 'shou_ji',
                      },
                    }}},[_c('span',[_vm._v(_vm._s(item.SYS_TOPIC))])])],1)}),0)])],2)]):_vm._e(),(_vm.$store.state.isEquipment == 'pc')?_c('el-col',{attrs:{"xs":24,"sm":6,"md":6,"lg":6,"xl":6}},_vm._l((_vm.second_s),function(a){return _c('div',{key:a.index,staticClass:"right"},[_c('router-link',{attrs:{"target":"_blank","to":{
                path: '/xq',
                query: {
                  qing: a.id,
                  mian: 'shou_ji',
                },
              }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(a.pub_cover),expression:"a.pub_cover"}],attrs:{"alt":""}}),_c('p',{staticClass:"title"},[_vm._v(_vm._s(a.SYS_TOPIC))]),_c('span',{staticClass:"title_t"},[_vm._v(_vm._s(_vm.$AwayTell(a.DESCRIPTION)))])])],1)}),0):_vm._e()],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }