var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.bksj_list.length)?_c('div',{staticClass:"newsstand",class:_vm.$store.state.isEquipment == 'pc' ? '' : 'phone'},[_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',[_c('router-link',{attrs:{"target":"_blank","to":"/bksj"}},[_c('HomeHeadline',{attrs:{"title":require('@/assets/image/home/报刊视界.png')}})],1),_c('div',{staticClass:"content"},[_c('el-row',{attrs:{"gutter":_vm.$store.state.isEquipment == 'pc' ? 20 : 0}},[_c('el-col',{attrs:{"xs":24,"sm":16,"md":16,"lg":16,"xl":16}},[_c('div',{staticClass:"left_top"},[_c('div',{staticClass:"head"},[(_vm.$store.state.isEquipment !== 'pc')?_c('div',{staticClass:"headl"},[_c('img',{attrs:{"src":require("../../assets/image/line.png"),"alt":""}}),_c('div',{staticClass:"round"})]):_vm._e(),_c('div',{staticClass:"cent"},[_c('router-link',{attrs:{"target":"_blank","to":{ path: '/woshuo' }}},[_c('img',{attrs:{"src":require("../../assets/image/woshuo.png")}}),_c('span',[_vm._v("我说")])])],1),_c('div',{staticClass:"headl"},[_c('div',{staticClass:"round"}),_c('img',{attrs:{"src":require("../../assets/image/line.png"),"alt":""}})])]),_vm._l((_vm.bksj_list),function(item){return _c('div',{key:item.index,staticClass:"left_bottom"},[_c('el-row',{attrs:{"gutter":_vm.$store.state.isEquipment == 'pc' ? 21 : 0}},[_c('el-col',{attrs:{"xs":24,"sm":6,"md":6,"lg":6,"xl":6}},[_c('router-link',{attrs:{"target":"_blank","to":{
                        path: '/xq',
                        query: {
                          qing: item.id,
                          mian: 'shou_bk',
                        },
                      }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.pub_cover),expression:"item.pub_cover"}],attrs:{"alt":""}})])],1),_c('el-col',{attrs:{"xs":24,"sm":18,"md":18,"lg":18,"xl":18}},[_c('router-link',{attrs:{"target":"_blank","to":{
                        path: '/xq',
                        query: {
                          qing: item.id,
                          mian: 'shou_bk',
                        },
                      }}},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.SYS_TOPIC))]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$AwayTell( item.DESCRIPTION, 0, _vm.$store.state.isSmallPc ? 60 : 124 )))]),_c('span',{staticClass:"particulars"},[_vm._v("[详情]")])])],1)],1)],1)})],2)]),_c('el-col',{attrs:{"xs":24,"sm":8,"md":8,"lg":8,"xl":8}},[_c('div',{staticClass:"right_top"},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"headl"},[_c('img',{attrs:{"src":require("../../assets/image/line.png"),"alt":""}}),_c('div',{staticClass:"round"})]),_c('div',{staticClass:"cent"},[_c('router-link',{attrs:{"target":"_blank","to":{ path: '/bksjList?id=48' }}},[_c('img',{attrs:{"src":require("../../assets/image/ping.png")}}),_c('span',[_vm._v("融合报道")])])],1),_c('div',{staticClass:"headl"},[_c('div',{staticClass:"round"}),_c('img',{attrs:{"src":require("../../assets/image/line.png"),"alt":""}})])])]),_vm._l((_vm.rong_first),function(a){return _c('div',{key:a.index,staticClass:"right_bottom"},[_c('router-link',{staticClass:"first",attrs:{"target":"_blank","to":{
                  path: '/xq',
                  query: {
                    qing: a.id,
                    mian: 'shou_bkr',
                  },
                }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(a.pub_cover),expression:"a.pub_cover"}],staticClass:"right_bottom_img",attrs:{"alt":""}}),_c('p',{staticClass:"right_title"},[_vm._v(_vm._s(_vm.$AwayTell(a.SYS_TOPIC, 0, 43)))])]),_c('div',{staticClass:"bottom_text"},[_c('ul',_vm._l((_vm.rong_sheng),function(item){return _c('li',{key:item.index},[_c('router-link',{attrs:{"target":"_blank","to":{
                        path: '/xq',
                        query: {
                          qing: item.id,
                          mian: 'shou_bkr',
                        },
                      }}},[_c('span',[_vm._v(_vm._s(item.SYS_TOPIC))])])],1)}),0)])],1)})],2)],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }