var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Interview"},[_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',[_c('router-link',{attrs:{"target":"_blank","to":{ path: '/fangtan' }}},[_c('HomeHeadline',{attrs:{"title":require('@/assets/image/home/访谈.png')}})],1),_c('div',{staticClass:"content"},[_c('el-row',{attrs:{"gutter":_vm.$store.state.isEquipment == 'pc' ? 10 : 0}},[_c('el-col',{attrs:{"xs":24,"sm":12,"md":12,"lg":12,"xl":12}},_vm._l((_vm.firstfang),function(a){return _c('div',{key:a.index,staticClass:"content_card left"},[_c('router-link',{attrs:{"target":"_blank","to":{
                path: '/xq',
                query: {
                  qing: a.id,
                  mian: 'shou_fang',
                },
              }}},[_c('div',{staticClass:"card_img card_img_left"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(a.pub_cover),expression:"a.pub_cover"}]})]),_c('div',{staticClass:"card_text"},[_c('p',{staticClass:"card_brief"},[_vm._v(_vm._s(a.SYS_TOPIC))]),_c('p',{staticClass:"card_content"},[_vm._v(" "+_vm._s(_vm.$AwayTell(a.DESCRIPTION, 0, 500))+" ")])])])],1)}),0),(_vm.$store.state.isEquipment == 'pc')?_c('el-col',{attrs:{"xs":24,"sm":12,"md":12,"lg":12,"xl":12}},[_c('div',[_c('el-row',{attrs:{"gutter":_vm.$store.state.isEquipment == 'pc' ? 10 : 0}},_vm._l((_vm.remain),function(item){return _c('el-col',{key:item.index,attrs:{"xs":24,"sm":12,"md":12,"lg":12,"xl":12}},[_c('router-link',{attrs:{"target":"_blank","to":{
                    path: '/xq',
                    query: {
                      qing: item.id,
                      mian: 'shou_fang',
                    },
                  }}},[_c('div',{staticClass:"content_card"},[_c('div',{staticClass:"card_img card_img_right"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.pub_cover),expression:"item.pub_cover"}]})]),_c('div',{staticClass:"card_text"},[_c('p',{staticClass:"card_brief"},[_vm._v(_vm._s(item.SYS_TOPIC))]),_c('p',{staticClass:"card_content"},[_vm._v(" "+_vm._s(_vm.$AwayTell(item.DESCRIPTION, 0, 400))+" ")])])])])],1)}),1)],1)]):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }