<template>
  <!-- phone -->
  <div class="nav_phone">
    <el-drawer
      :visible.sync="nav_drawer"
      :direction="direction"
      :before-close="handleClose"
      size="40%"
    >
      <ul>
        <li
        v-for="(item, index) in list"
        :key="index"
        @click="isShow()"
        >
        <router-link target="_blank" :to="item.route" >{{ item.text }}</router-link>
      </li>
      </ul>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "",

  components: {},

  data() {
    return {
      list: [
        {
          text: "社科视频首页",
          route: "/",
        },
        {
          text: "资讯",
          route: {path:'/zixun'},
        },
        {
          text: "报刊视界",
          route: "/bksj",
        },
        {
          text: "学人",
          route: "/xueren",
        },
        {
          text: "访谈",
          route: {path:'/fangtan'},
        },
        {
          text: "专题",
          route: "/zhuanti",
        },
        {
          text: "我说",
          route: {path:'/woshuo',query:{wo:5}},
        },
        {
          text: "直播",
          route: "/zhibo",
        },
      ],
      // nav_drawer: this.$store.state.PhoneNavShow ? false: this.$store.state.PhoneNavShow,
      nav_drawer: this.$store.state.PhoneNavShow,
      direction: "rtl",
    };
  },
  created() {
    
  },
  watch: {
    "$store.state.PhoneNavShow"(n, o) {
      this.nav_drawer = n;
    },
  },
  methods: {
    handleClose(done) {
      done();
      this.nav_drawer = !this.$store.state.PhoneNavShow
      this.$store.commit("setPhoneNavShow", this.nav_drawer);
    },
    isShow(){
      this.nav_drawer = !this.$store.state.PhoneNavShow
      this.$store.commit("setPhoneNavShow", this.nav_drawer);
    }
  },
};
</script>

<style lang="scss">
.nav_phone {
  
  li {
        padding: 1rem 0;
        min-width: 6.25rem;
        text-align: center;
        font-size: 1.25rem;
        a{
          color: #212121;
        }
      }
      .el-drawer__close-btn{
        font-size: 1.875rem;
        margin-right: 2rem;
      }
      .el-drawer__header{
        margin-bottom: 1rem!important;
      }
}
</style>