import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import VueRouter from 'vue-router'
Vue.use(VueRouter);
import router from '@/router'
import 'element-ui/lib/theme-chalk/index.css'
import element from '@/assets/js/element'
Vue.use(element)
// 视频播放
import 'videojs-contrib-hls'
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
// 多次点击同一个路由报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};
// vuex
import store from './store'

//移动端无限滚动
import infiniteScroll from "vue-infinite-scroll";
 Vue.use(infiniteScroll);

// 日期格式
import ModuleDate from './assets/js/Date';
Vue.prototype.$ModuleDate = ModuleDate;

import AwayTell from './assets/js/AwayTell'
Vue.prototype.$AwayTell = AwayTell

import {Message} from 'element-ui'
Vue.prototype.$message = Message;


// 轮播图
import SomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
Vue.use(SomeSwiper);

router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
})

//图片懒加载
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload,{
  loading:require('@/assets/image/loading.png')
})

new Vue({
  store,
  render: h => h(App),
  router
}).$mount('#app')
