<template>
  <div>
    <!-- pc端 -->
    <div
      class="column"
      v-if="
        this.$store.state.isEquipment == 'pc' &&
        $route.path !== '/privateDetails'
      "
    >
      <div class="fixd_box mainWidth">
        <div class="column_left">
          <a href="http://www.cssn.cn/">
            <img src="@/assets/image/home/logo.png" alt="" />
          </a>
          <div class="title">
            <a href="http://www.cssn.cn/">
              <span>首页</span>
            </a>
            <span class="longString"></span>
            <span @click="drawer = true" class="web_btn">网站导航</span>
          </div>
        </div>
        <div class="column_right">
          <div class="search">
            <el-input
              placeholder="请输入您要搜索的内容"
              @keyup.enter.native="changinp(searchInput)"
              v-model="searchInput"
              size="mini"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="shipjians(searchInput)"
              ></el-button>
            </el-input>
          </div>
        </div>
      </div>
    </div>

    <!-- PC端网页导航 -->
    <div class="web_nav">
      <el-drawer
        :visible.sync="drawer"
        direction="ttb"
        :before-close="handleClose"
        size="100vh"
      >
        <div class="title">
          <img src="@/assets/image/home/web_logo.png" alt="" />
        </div>
        <div class="container">
          <ul class="navtop list_1">
            <li class="icon_learn" v-for="item in list_1" :key="item.index">
              <a :href="item.href" target="_blank" ignoreapd="true">{{
                item.text
              }}</a>
            </li>
          </ul>
          <ul class="navtop list_2">
            <li v-for="item in list_2" :key="item.index">
              <a :href="item.href" target="_blank" ignoreapd="true">{{
                item.text
              }}</a>
            </li>
          </ul>
          <h3 class="subheading">学科体系</h3>
          <ul class="navtop list_3">
            <li v-for="item in list_3" :key="item.index">
              <a :href="item.href" target="_blank" ignoreapd="true">{{
                item.text
              }}</a>
            </li>
          </ul>
          <h3 class="subheading">新媒体矩阵</h3>
          <div class="fanzhuan">
            <div class="container" v-for="item in list_4" :key="item.index">
              <div class="card">
                <div class="card_main_style card_front">
                  <img v-lazy="item.frontSrc" alt="图片" />
                  <p>{{ item.text }}</p>
                </div>
                <div class="card_main_style card_back">
                  <img v-lazy="item.backSrc" alt="图片" />
                  <p>{{ item.text }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>

    <!-- 手机端 -->
    <div
      class="column_phone"
      v-if="
        $store.state.isEquipment === 'phone' &&
        $route.path !== '/privateDetails'
      "
    >
      <el-row type="flex" justify="center">
        <el-col :span="22">
          <div class="column_left">
            <a href="http://www.cssn.cn/">
              <img src="@/assets/image/home/logo.png" alt="" />
            </a>
          </div>
          <div class="column_right">
            <div class="search">
              <!-- <img src="../assets/image/sk_m_searchbtn.png" alt="" @click="isShow()" class="search_btn"/> -->
              <el-button
                icon="el-icon-my-search"
                circle
                @click="isShow()"
                size="small"
                class="search_btns"
              ></el-button>
            </div>
            <div class="column_web_nav">
              <el-button
                icon="el-icon-my-mean"
                circle
                @click="Phonedrawer = true"
                size="small"
                class="mean_btn"
              ></el-button>
            </div>
            <div class="column_nav">
              <el-button
                icon="el-icon-more"
                circle
                @click="navShow()"
                size="small"
              ></el-button>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="search_input" v-if="this.search">
            <el-input
              placeholder="请输入您要搜索的内容"
              v-model="searchInput"
              @keyup.enter.native="changinp(searchInput)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="shipjians(searchInput)"
              ></el-button>
            </el-input>
            <el-button
              type="text"
              slot="append"
              icon="el-icon-close"
              class="close"
              @click="close()"
            ></el-button>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- 手机端网页导航 -->
    <div class="Phone_web_nav">
      <el-drawer
        :visible.sync="Phonedrawer"
        direction="ttb"
        :before-close="handleClose"
        size="100vh"
        :withHeader="true"
      >
        <div class="title">
          <img src="@/assets/image/home/web_logo.png" alt="" />
        </div>
        <el-row type="flex" justify="center">
          <el-col>
            <ul class="navtop list_1">
              <li class="icon_learn" v-for="item in list_1" :key="item.index">
                <a :href="item.href" target="_blank" ignoreapd="true">{{
                  item.text
                }}</a>
              </li>
            </ul>
            <ul class="navtop list_2">
              <li v-for="item in list_2" :key="item.index">
                <a :href="item.href" target="_blank" ignoreapd="true">{{
                  item.text
                }}</a>
              </li>
            </ul>
            <h3 class="subheading">学科体系</h3>
            <ul class="navtop list_3">
              <li v-for="item in list_3" :key="item.index">
                <a :href="item.href" target="_blank" ignoreapd="true">{{
                  item.text
                }}</a>
              </li>
            </ul>
          </el-col>
        </el-row>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import NavType from "@/components/NavType.vue";
export default {
  components: {
    NavType,
  },
  data() {
    return {
      drawer: false,
      Phonedrawer: false,
      nav_drawer: this.$store.state.PhoneNavShow,
      souval: "",
      list_1: [
        {
          text: "中心",
          href: "http://www.cssn.cn/xxzx/",
        },
        {
          text: "学术中国",
          href: "http://www.cssn.cn/xszg/",
        },
        {
          text: "智库中国 ",
          href: "http://www.cssn.cn/zkzg/",
        },
        {
          text: "学术地图",
          href: "http://www.cssn.cn/skwxsdt/",
        },
        {
          text: "社科视频",
          href: "http://v.cssn.cn/",
        },
        {
          text: "中国学派",
          href: "http://www.cssn.cn/dkzgxp/",
        },
        {
          text: "国际观察",
          href: "http://www.cssn.cn/gjgc/",
        },
        {
          text: "考古现场",
          href: "http://www.cssn.cn/kgxc/",
        },
        {
          text: "公告 · 招聘",
          href: "http://www.cssn.cn/ggzp/",
        },
      ],
      list_2: [
        {
          text: "志鉴中国",
          href: "http://www.cssn.cn/zjzg/",
        },
        {
          text: "社科关注",
          href: "http://www.cssn.cn/skgz/",
        },
        {
          text: "社科要论 ",
          href: "http://www.cssn.cn/skyl/",
        },
        {
          text: "社科好书",
          href: "http://www.cssn.cn/dsskhs/",
        },
        {
          text: "社科青年说",
          href: "http://www.cssn.cn/skqns/",
        },
        {
          text: "网络强国论坛",
          href: "http://www.cssn.cn/wlqglt/",
        },
        {
          text: "社科融媒体",
          href: "http://www.cssn.cn/skrmt/",
        },
        {
          text: "网刊",
          href: "http://www.cssn.cn/wkskjh/",
        },
      ],
      list_3: [
        {
          text: "马克思主义",
          href: "http://www.cssn.cn/mkszy/",
        },
        {
          text: "哲学",
          href: "http://www.cssn.cn/zx/",
        },
        {
          text: "经济学 ",
          href: "http://www.cssn.cn/jjx/",
        },
        {
          text: "法学",
          href: "http://www.cssn.cn/fx/",
        },
        {
          text: "历史学",
          href: "http://www.cssn.cn/lsx/",
        },
        {
          text: "文学",
          href: "http://www.cssn.cn/wx/",
        },
        {
          text: "新闻传播学",
          href: "http://www.cssn.cn/xwcbx/",
        },
        {
          text: "艺术学",
          href: "http://www.cssn.cn/ysx/",
        },
        {
          text: "政治学",
          href: "http://www.cssn.cn/zzx/",
        },
        {
          text: "社会学",
          href: "http://www.cssn.cn/shx/",
        },
        {
          text: "民族学",
          href: "http://www.cssn.cn/mzx/",
        },
        {
          text: "教育学",
          href: "http://www.cssn.cn/jyx/",
        },
        {
          text: "管理学",
          href: "http://www.cssn.cn/glx/",
        },
        {
          text: "军事学",
          href: "http://www.cssn.cn/jsx/",
        },
        {
          text: "中共党史党建",
          href: "http://www.cssn.cn/zgdsdj/",
        },
        {
          text: "区域国别学",
          href: "http://www.cssn.cn/qygbx/",
        },
        {
          text: "国家安全学",
          href: "http://www.cssn.cn/gjaqx/",
        },
      ],
      list_4: [
        {
          frontSrc:
            "http://www.cssn.cn/index/mtjz/202209/W020220922337226245876.png",
          backSrc:
            "http://www.cssn.cn/index/mtjz/202209/W020220929538050877342.jpg",
          text: "微信小程序",
        },
        {
          frontSrc:
            "http://www.cssn.cn/index/mtjz/202209/W020220922336887208883.png",
          backSrc:
            "http://www.cssn.cn/index/mtjz/202209/W020220929539559790487.jpg",
          text: "微信公众号",
        },
        {
          frontSrc:
            "http://www.cssn.cn/index/mtjz/202209/W020220922336458060142.png",
          backSrc:
            "http://www.cssn.cn/index/mtjz/202209/W020220929539793588017.jpg",
          text: "中国学派",
        },
        {
          frontSrc:
            "http://www.cssn.cn/index/mtjz/202209/W020230615600845961620.png",
          backSrc:
            "http://www.cssn.cn/index/mtjz/202209/W020230616566952919336.jpg",
          text: "中国社会科学报",
        },
        {
          frontSrc:
            "http://www.cssn.cn/index/mtjz/202209/W020220929459900615313.png",
          backSrc:
            "http://www.cssn.cn/index/mtjz/202209/W020220929540194201728.jpg",
          text: "新浪微博",
        },
        {
          frontSrc:
            "http://www.cssn.cn/index/mtjz/202209/W020220929460609289083.png",
          backSrc:
            "http://www.cssn.cn/index/mtjz/202209/W020220929540425896749.jpg",
          text: "今日头条号",
        },
      ],
      searchInput: "",
      search: false,
    };
  },
  methods: {
    handleClose(done) {
      done();
    },
    shipjians(value) {
      this.$store.commit("setIsSearchInput", value);
      let routerJump = this.$router.resolve({
        path: "/jiansuo",
        query: { inp_value: value == "" ? "社会" : value },
      });
      window.open(routerJump.href, "_blank");
    },
    changinp(value) {
      this.$store.commit("setIsSearchInput", value);
      let routerJump = this.$router.resolve({
        path: "/jiansuo",
        query: { inp_value: value == "" ? "社会" : value },
      });
      window.open(routerJump.href, "_blank");
    },

    // 搜索框
    isShow() {
      this.search = !this.search;
    },
    close() {
      this.search = !this.search;
    },

    // 手机端导航栏
    navShow() {
      this.nav_drawer = !this.$store.state.PhoneNavShow;
      this.$store.commit("setPhoneNavShow", this.nav_drawer);
    },
  },
  created() {},
};
</script>

<style lang="scss">
.column {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 3.75rem;
  line-height: 3.75rem;
  box-shadow: 0 0 20px 0 #cccccc;
  img {
    width: 8.75rem;
    margin-top: 1.0625rem;
    float: left;
  }

  .column_left {
    float: left;

    a {
      text-decoration: none;
      color: #000;
    }

    span {
      font-size: 0.875rem;
    }

    .title {
      float: left;
      color: #000;
      line-height: 4.5rem;

      span:first-child {
        padding-left: 1.25rem;
        line-height: 3.75rem;
      }

      .longString::after {
        height: 0.625rem;
        display: inline-block;
        border-right: 1px solid #000;
        margin: 0 0.625rem;
        font-weight: normal;
        color: #000;
        content: "";
      }

      .web_btn {
        cursor: pointer;
      }
    }
  }

  .column_right {
    float: right;

    .search {
      width: 15rem;

      .el-input {
        border: 1px solid #dcdfe6;
        border-radius: 50px;
        overflow: hidden;

        .el-input__inner {
          border: none;
          height: 1.875rem;
          font-size: 0.875rem;
          padding: 0 1rem;
        }

        .el-input-group__append {
          background: #ffffff;
          border: none;

          .el-icon-search {
            font-size: 1rem;
          }
        }
      }
    }
  }
}

.web_nav {
  .el-drawer {
    overflow: auto;
  }

  .el-drawer__header {
    padding: 0;
    margin: 0;
  }

  .el-drawer__body {
    width: 1280px;
    margin: auto;
    padding-top: 70px;
    overflow: visible;
  }

  @media screen and (max-width: 1440px) {
    .el-drawer__body {
      width: 800px;
      padding-top: 50px;

      .title img {
        width: 250px;
      }

      .list_1 li {
        font-size: 16px;
        margin-left: 26px;
      }

      .list_1 li:first-child {
        text-indent: 34px;
      }

      .list_1 {
        margin-top: 30px;
        padding-bottom: 20px;
      }

      .list_2 {
        padding-bottom: 20px;

        li {
          margin-top: 21px;
          line-height: normal;
        }
      }

      .list_2 li,
      .list_3 li {
        width: 110px;
      }

      .fanzhuan {
        .container {
          width: 130px;
          height: 151px;
          margin-right: 0;
        }

        .card {
          width: 120px;
          height: 130px;

          p {
            margin-top: 10px;
          }

          .card_front {
            padding: 30px;

            img {
              width: 70%;
            }
          }

          .card_back img {
            width: 90%;
          }

          p {
            font-size: 12px;
          }
        }
      }
    }
  }

  .el-drawer__close-btn {
    font-size: 2.3rem;
    position: absolute;
    top: 2.5rem;
    right: 5rem;
    color: #000;
  }

  .el-drawer__close-btn:hover {
    animation: myfirst 0.5s;
    -moz-animation: myfirst 0.5s;
    /* Firefox */
    -webkit-animation: myfirst 0.5s;
    /* Safari and Chrome */
    -o-animation: myfirst 0.5s;
    /* Opera */
  }

  @keyframes myfirst {
    from {
      transform: rotateZ(0);
    }

    to {
      transform: rotateZ(360deg);
    }
  }

  @-webkit-keyframes myfirst

  /* Safari 与 Chrome */ {
    from {
      transform: rotateZ(0);
    }

    to {
      transform: rotateZ(360deg);
    }
  }

  .title {
    text-align: center;

    img {
      width: 25.5rem;
    }
  }

  .list_1 {
    padding-bottom: 30px;
    margin-top: 50px;
    overflow: hidden;

    li:first-child {
      box-sizing: border-box;
      background: url("../assets/image/home/xuexi.png") no-repeat 0px 4px;
      text-indent: 2.625rem;
      background-size: 2.375rem 1.625rem;
      padding-left: 0;
      margin-left: 0;

      a {
        color: #bd0d0d;
      }
    }

    li {
      font-size: 1.25rem;
      margin-left: 4rem;
      float: left;
    }
  }

  .navtop {
    border-bottom: 1px solid #ececec;

    li {
      line-height: 35px;
      display: inline-block;

      list-style: none;

      a {
        color: #212121;
        text-decoration: none;
      }
    }
  }

  .list_2 {
    padding-bottom: 30px;

    li {
      font-size: 1rem;
      width: 175px;
      margin: 1.5625rem 0 0 0;
      line-height: 21px;
    }
  }

  .list_3 {
    padding-bottom: 1.5rem;

    li {
      font-size: 1rem;
      width: 10.9375rem;
      margin: 1.5625rem 0 0 0;
      line-height: 21px;
    }
  }

  .subheading {
    font-size: 1.125rem;
    color: #2181b0;
    margin-top: 1.25rem;
  }

  .fanzhuan {
    border-bottom: 1px solid #ececec;
    margin-bottom: 2rem;

    .container {
      position: relative;
      z-index: 0;
      width: 11.625rem;
      height: 12.5rem;
      margin-right: 1.5rem;
      display: inline-block;
      margin-top: 2rem;

      &:hover {
        .card {
          transform: rotateY(180deg);
        }
      }
    }

    .card {
      width: 11.625rem;
      height: 12.5rem;
      position: relative;
      z-index: 500;
      transition: transform 0.5s;
      transform-style: preserve-3d;
      /* 兼容浏览器 */
      -webkit-transform-style: preserve-3d;
      /* Safari 和 Chrome */
      -moz-transform-style: preserve-3d;
      /* Firefox */
      -ms-transform-style: preserve-3d;
      /* IE9 */
      -o-transform-style: preserve-3d;
      /* Opera */
    }

    .card_main_style {
      width: 100%;
      height: 100%;
      text-align: center;
      position: absolute;
    }

    .card_front {
      z-index: 1000;
      pointer-events: none;
      padding: 2.5rem;
      box-sizing: border-box;
      background: url("../assets/image/home/newMedia_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .card_back {
      z-index: 2000;
      padding: 1.875rem;
      box-sizing: border-box;
      backface-visibility: hidden;
      transform: rotateY(180deg);
      background: url("../assets/image/home/newMedia_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}

.column_phone {
  //  height: 4rem;

  .column_left {
    float: left;
    width: 45%;
    margin-top: 0.9375rem;

    img {
      width: 100%;
    }
  }

  .column_right {
    float: right;
    text-align: right;

    .column_nav,
    .search,
    .equipment,
    .column_web_nav {
      float: left;
      margin-top: 0.9375rem;
      padding: 0 0.2rem;
      margin-bottom: 1rem;

      // img{
      //   width: 1.875rem;
      // }
    }

    .search_btn {
      // width: 2.3rem;
      float: left;
      // margin-top: 0.9375rem;
      // padding: 0 0.2rem;
      // margin-bottom: 1rem;
      width: 2rem;
      height: 2rem;
    }

    .search_btns,
    .mean_btn {
      padding: 5px;
    }
  }

  .search_input {
    position: absolute;
    width: 100%;
    padding: 0rem 1rem;
    margin-bottom: 1rem;
    float: left;
    height: 4.0625rem;
    background: #fff;
    z-index: 999;

    .el-input-group {
      width: 80%;
    }

    .close {
      margin-left: 1.25rem;
      font-size: 1.5rem;
    }
  }
}

.Phone_web_nav {
  .el-drawer__close-btn {
    font-size: 2.3rem;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .el-drawer__close-btn:hover {
    animation: myfirst 0.5s;
    -moz-animation: myfirst 0.5s;
    /* Firefox */
    -webkit-animation: myfirst 0.5s;
    /* Safari and Chrome */
    -o-animation: myfirst 0.5s;
    /* Opera */
  }

  @keyframes myfirst {
    from {
      transform: rotateZ(0);
    }

    to {
      transform: rotateZ(360deg);
    }
  }

  @-webkit-keyframes myfirst

  /* Safari 与 Chrome */ {
    from {
      transform: rotateZ(0);
    }

    to {
      transform: rotateZ(360deg);
    }
  }

  .title {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    text-align: center;

    img {
      width: 16.3rem;
    }
  }

  .list_1 {
    padding: 1rem 0;

    li:first-child {
      box-sizing: border-box;

      a {
        color: #bd0d0d;
        background: url("../assets/image/home/xuexi.png") no-repeat 0px 4px;
        text-indent: 1.5rem;
        background-size: 30%;
        padding-left: 0px;
        display: block;
        background-position: 1rem 0.4rem;
      }
    }

    li {
      font-weight: 600;
    }
  }

  .list_2 {
    margin-top: 1rem;
    padding-bottom: 1rem;
  }

  .list_3 {
    margin: 1rem 0;
    padding-bottom: 1rem;
  }

  .navtop {
    border-bottom: 3px solid #ececec;

    li {
      font-size: 0.75rem;
      line-height: 2.1875rem;
      width: 30%;
      display: inline-block;
      background: #f5f5f5;
      border-radius: 5px;
      text-align: center;
      margin: 0.3125rem;
      list-style: none;

      a {
        color: #212121;
        text-decoration: none;
      }
    }
  }

  .subheading {
    font-size: 1rem;
    color: #2181b0;
    margin-top: 1.25rem;
    padding-left: 0.3125rem;
    font-family: "Microsoft YaHei", \5FAE\8F6F\96C5\9ED1;
  }

  // .fanzhuan {
  //   border-bottom: 1px solid #ececec;
  //   margin-bottom: 2rem;

  //   .container {
  //     position: relative;
  //     z-index: 0;
  //     width: 11.625rem;
  //     height: 12.5rem;
  //     margin-right: 1rem;
  //     display: inline-block;
  //     margin-top: 2rem;

  //     &:hover {
  //       .card {
  //         transform: rotateY(180deg);
  //       }
  //     }
  //   }
  //   .card {
  //     width: 11.625rem;
  //     height: 12.5rem;
  //     position: relative;
  //     z-index: 500;
  //     transition: transform 0.5s;
  //     transform-style: preserve-3d;
  //     /* 兼容浏览器 */
  //     -webkit-transform-style: preserve-3d; /* Safari 和 Chrome */
  //     -moz-transform-style: preserve-3d; /* Firefox */
  //     -ms-transform-style: preserve-3d; /* IE9 */
  //     -o-transform-style: preserve-3d; /* Opera */
  //   }
  //   .card_main_style {
  //     width: 100%;
  //     height: 100%;
  //     text-align: center;
  //     position: absolute;
  //   }
  //   .card_front {
  //     z-index: 1000;
  //     pointer-events: none;
  //     padding: 2.5rem;
  //     box-sizing: border-box;
  //     background: url("../assets/image/home/newMedia_bg.png");
  //     background-size: 100% 100%;
  //     background-repeat: no-repeat;
  //   }
  //   .card_back {
  //     z-index: 2000;
  //     padding: 1.875rem;
  //     box-sizing: border-box;
  //     backface-visibility: hidden;
  //     transform: rotateY(180deg);
  //     background: url("../assets/image/home/newMedia_bg.png");
  //     background-size: 100% 100%;
  //     background-repeat: no-repeat;
  //   }
  // }
}

.stickyNav .Nav {
  line-height: 3.625rem;
  height: 3.625rem;
}
</style>
<style lang="scss">
.column_phone {
  .el-button.is-circle {
    color: #347ba4 !important;
    border-color: #c6dfeb !important;
  }
}

.el-icon-my-search {
  background: url("~@/assets/image/sousuo.png") center no-repeat;
  // font-size: 14px;
  background-size: 100% 100%;
  width: 1.25rem;
  height: 1.25rem;
}

.el-icon-my-mean {
  background: url("~@/assets/image/sangeheng.png") center no-repeat;
  // font-size: 14px;
  background-size: 100% 100%;
  width: 1.25rem;
  height: 1.25rem;
}
</style>