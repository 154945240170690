<template>
  <div class="content content-third">
    <div v-if="$store.state.isEquipment === 'pc'">
      <Swiper :options="swiperOption" v-if="list3.length">
        <SwiperSlide v-for="(page, index) in list3" :key="index" class="page">
          <el-row :gutter="20">
            <el-col :span="12" class="singleItem" :class="'singleItem' + index" v-for="(item, index) in page"
              :key="item.id">
              <router-link class="crad" target="_blank" :to="{
                path: '/xq',
                query: {
                  qing: item.id,
                  mian: `shx${0}`,
                },
              }">
                <img v-lazy="item.pub_cover" alt="" />
                <div class="imgRightBox">
                  <p class="name">{{ item.FUBIAOTI || '...' }}</p>
                  <p class="title1">{{ item.SYS_TOPIC }}</p>
                  <p class="text">
                    {{ $AwayTell(item.DESCRIPTION) }}
                  </p>
                </div>
              </router-link>
            </el-col>
          </el-row>
        </SwiperSlide>
        <div class="swiper-pagination" slot="pagination"></div>
      </Swiper>
    </div>
    <div v-else class="phone">
      <Swiper :options="swiperOption_phone" v-if="list3.length">
        <SwiperSlide v-for="(item, index) in list" :key="index">
          <router-link class="crad" target="_blank" :to="{
            path: '/xq',
            query: {
              qing: item.id,
              mian: `shx${0}`,
            },
          }">
            <img :src="item.pub_cover" v-if="index == 0 || index == (list3.length - 1)">
            <img v-lazy="item.pub_cover" alt="" v-else>
            <div>
              <p class="name">{{ item.FUBIAOTI }}</p>
              <p class="title1">{{ item.SYS_TOPIC }}</p>
              <p class="text">
                {{ $AwayTell(item.DESCRIPTION) }}
              </p>
            </div>
          </router-link>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script>
import api from "@/api/index.js";
export default {
  data() {
    return {
      swiperOption: {
        loop: true,

        EffectCards: true,
        slidesPerView: this.$store.state.smallPc ? 2 : 1,
        spaceBetween: 20,
        pagination: { el: ".swiper-pagination", clickable: true }, // 分页按钮
      },
      swiperOption_phone: {
        loop: true,
        EffectCards: true,
        slidesPerView: 1,
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false,
        // },
      },
    };
  },
  props: ['list'],
  computed: {
    list3() {
      let result = [];
      if (!this.list) { return [] }
      for (let index = 0; index < this.list.length / 4; index++) {
        result.push(this.list.slice(index * 4, (index + 1) * 4));
      }
      return result;
    },
  },
  created() {
    console.log('xxx')
    // this.getlist3();
  },
  methods: {
    getlist3() {
      api
        .post("spc/cms/publish/queryList.do", {
          s: 2,
          c: this.$route.name === 'index' ? 63 : 71, //63
        })
        .then((res) => {
          this.list = res.result;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./common.scss";

.content-third {
  .crad {
    background: url("./bg_item.png") no-repeat center/cover;
    height: 269px;
    align-items: center;
    justify-content: space-between;
  }

  img {
    width: 20rem;
    height: 15rem;
    flex-grow: 0;
  }

  /deep/ .swiper-pagination-bullet {
    width: 0.8rem;
    height: 0.8rem;
  }
}

.content .phone .crad {
  height: auto;

  .text {
    -webkit-line-clamp: 8;
    height: 14rem;
    margin-bottom: 1rem;
    max-height: none;
  }
}

.imgRightBox {
  width: calc(100% - 21rem);
}
</style>