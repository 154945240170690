<!-- 纪实 -->
<template>
  <div class="Reality" v-if="shou_j.length">
    <el-row type="flex" justify="center">
      <el-col>
        <router-link target="_blank" :to="{ path: '/homeList', query: { id: 68 } }">
          <HomeHeadline :title="require('@/assets/image/home/纪实.png')" />
        </router-link>

        <div class="content">
          <el-row :gutter="$store.state.isEquipment == 'pc' ? 12 : 0">
            <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
              <div class="slideshow">
                <Swiper :options="swiperOption">
                  <SwiperSlide v-for="(item, index) in shou_j" :key="item.index">
                    <div class="crad">
                      <router-link target="_blank" :to="{
                        path: '/xq',
                        query: {
                          qing: item.id,
                          mian: 'shou_ji',
                        },
                      }">
                        <img :src="item.pub_cover" v-if="index == 0 || index == (shou_j.length - 1)">
                        <img v-lazy="item.pub_cover" alt="" :key="item.pub_cover" v-else>
                        <p class="title">{{ item.SYS_TOPIC }}</p>
                      </router-link>
                    </div>
                  </SwiperSlide>
                  <div class="swiper-pagination" slot="pagination"></div>
                </Swiper>
              </div>
            </el-col>

            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" v-if="$store.state.isEquipment == 'pc'">
              <div class="middle">
                <div class="middle_top" v-for="a in first_s" :key="a.index">
                  <router-link target="_blank" :to="{
                    path: '/xq',
                    query: {
                      qing: a.id,
                      mian: 'shou_ji',
                    },
                  }">
                    <p class="title">{{ a.SYS_TOPIC }}</p>
                    <p class="text">
                      {{
                        $AwayTell(a.DESCRIPTION, 0, 76)
                      }}
                      <span class="sppa1">[详细]</span>
                    </p>
                  </router-link>
                </div>
                <div class="middle_bottom">
                  <ul>
                    <li v-for="item in shengyu" :key="item.index">
                      <router-link target="_blank" :to="{
                        path: '/xq',
                        query: {
                          qing: item.id,
                          mian: 'shou_ji',
                        },
                      }">
                        <span>{{ item.SYS_TOPIC }}</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </el-col>

            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" v-if="$store.state.isEquipment == 'pc'">
              <div class="right" v-for="a in second_s" :key="a.index">
                <router-link target="_blank" :to="{
                  path: '/xq',
                  query: {
                    qing: a.id,
                    mian: 'shou_ji',
                  },
                }">
                  <img v-lazy="a.pub_cover" alt="" />
                  <p class="title">{{ a.SYS_TOPIC }}</p>
                  <span class="title_t">{{
                    $AwayTell(a.DESCRIPTION)
                  }}</span>
                </router-link>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import HomeHeadline from "@/components/HomeHeadline.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import api from "@/api/index.js";
import "swiper/css/swiper.css";
export default {
  components: {
    HomeHeadline,
    Swiper,
    SwiperSlide,
  },
  props: {},
  data() {
    return {
      shou_j: "",
      first_s: "",
      shengyu: "",
      second_s: "",
      swiperOption: {
        autoplay: {
          disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay
          delay: 3000, // 自动切换的时间间隔（单位ms）
        },
        initialSlide: 0,
        loop: true,
        pagination: { el: ".swiper-pagination",clickable:true }, // 分页按钮
        EffectCards: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      },
    };
  },
  computed: {},
  methods: {},
  mounted() {
    api
      .post("spc/cms/publish/queryList.do", {
        s: 2,
        c: 68, //68没数据暂时69代替
      })
      .then((res) => {
        console.log('纪实', res);
        // 左
        this.shou_j = res.result.slice(0, 5);
        // 中下
        this.shengyu = res.result.slice(7, this.$store.state.isSmallPc ? 12 : 14);
        // 中上
        this.first_s = res.result.slice(6, 7);
        // 右
        this.second_s = res.result.slice(5, 6);
      });
  },
};
</script>
<style scoped lang="scss">
.Reality {
  .content {
    margin-top: 1rem;

    .slideshow {
      .swiper-pagination {
        text-align: right;
        box-sizing: border-box;
        padding-right: 1rem;
      }

      .swiper-pagination-bullet {
        background: #ffffff;
      }

      .crad {
        img {
          width: 100%;
          // height:auto; 
          aspect-ratio: 4/3;
          display: block;
          height: 432px;
        }

        @media screen and (max-width: 1440px) {
          img {
            height: 308px;
          }
        }

        .title {
          font-size: 1.25rem;
          font-weight: bold;
          color: #ffffff;
          line-height: 1.5rem;
          height: 1.5rem;
          position: absolute;
          bottom: 1.25rem;
          /* left: 1rem; */
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
          text-overflow: ellipsis;
          padding: 0 0.5rem;
          box-sizing: border-box;
        }
      }
    }

    .middle {
      background: #f1f4fb;
      padding: 0.86rem 1.5rem;
      box-sizing: border-box;
      height: 100%;

      .middle_top {
        .title {
          font-size: 1.25rem;
          font-weight: bold;
          color: #212121;
          line-height: 2.5rem;
          margin-bottom: 0.125rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .title:hover {
          color: #277398;
        }

        .text {
          font-size: 1rem;
          font-weight: 400;
          color: #707070;
          line-height: 1.75rem;
          text-align: justify;

          .sppa1 {
            color: #287498;
          }
        }
      }

      .middle_bottom {
        margin-top: 0.8rem;

        ul {

          // padding-left: 1rem;
          li {
            font-size: 1.125rem;
            color: #212121;
            line-height: 2rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          li::before {
            content: "\2022 ";
            font-size: 1.5rem;
            color: #266394;
            margin-right: 0.3125rem;
          }

          li:hover span {
            color: #277398;
            cursor: pointer;
          }
        }

        span {
          color: #212121;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      img {
        // width: 100%;
        // height: 13.25rem!important;
        width: 100%;
        height: auto;
        aspect-ratio: 4/3;
        height: 256px;
      }
      @media screen and(max-width:1440px) {
        img{
          height: 181px;
        }
      }

      .title {
        // position: static;
        font-size: 1.25rem;
        font-weight: bold;
        color: #212121;
        // height: 3rem;
        font-size: 1.25rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        // color: #ffffff;
        line-height: 1.875rem;
        margin: 1rem 0 0.5rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-wrap: break-word;
      }

      .title:hover {
        color: #277398;
      }

      .title_t {
        font-size: 1rem;
        font-weight: 400;
        color: #707070;
        line-height: 1.75rem;
        margin-top: 0.7rem !important;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
      }
    }
  }
}

@media screen and(max-width:1440px) and (min-width:768px) {
  .Reality .content .middle .middle_bottom {
    margin-top: 0;
  }

  .Reality .content .right .title_t {
    -webkit-line-clamp: 3;
  }
}
</style>