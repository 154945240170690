<template>
  <div class="home mainWidth" :class="{phone:$store.state.isEquipment === 'phone'}">
    
    
    <EmphasisShow />
    <Information />
    <Interview />
    <Newsstand  />
    <Shiping :sendText="sendText" />
    <Scholar />
    <Subject />
    <Achievement />
    <Bema />
    <Reality />
    <Book />
    <!-- <div class="separator"></div> -->
    <!-- <Footer /> -->
    <!-- <Scroll /> -->
  </div>
</template>

<script>

// import Navigation from "@/components/Navigation.vue";
import EmphasisShow from "@/pages/index/EmphasisShow";
import Information from "@/pages/index/Information";
import Interview from "@/pages/index/Interview";
import Newsstand from "@/pages/index/Newsstand.vue";
import Scholar from "@/pages/index/Scholar.vue";
import Subject from "@/pages/index/Subject";
import Achievement from "@/pages/index/Achievement";
import Bema from "@/pages/index/Bema";
import Reality from "@/pages/index/Reality";
import Book from "@/pages/index/Book";

import api from '@/api/index.js'
import Shiping from "@/components/Shiping.vue";



export default {
  name: "IndexPage",
  props: {
    msg: String,
  },
  components: {
    // Navigation,
    EmphasisShow,
    Information,
    Interview,
    Newsstand,
    Scholar,
    Subject,
    Achievement,
    Bema,
    Reality,
    Book,
    Shiping
    // NavigationPhone
    // Footer,
    // Scroll,
  },
  data() {
    return {
      sendText: []
      // equipment: this.$.state.isEquipment,
    };
  },
  created(){
    api.post('spc/cms/publish/queryList.do',{
        s:2,
        c:56
      }).then((res)=>{
        this.sendText = res.result.slice(0,3)
        // console.log("1222",res)
        // this.list = res.result

      })
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 2.5rem 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 .625rem;
}
a {
  color: #42b983;
}
// .home_Nav{
//   top: 29rem;
//   width: 100%;
//   position: absolute;
// }
.home.phone{
  padding:0 1.5rem;
  .master{
    margin:0 -1.5rem;
  }
}

</style>
