const addStyle = () => {
  var style = document.createElement("style");
  style.type = "text/css";
  style.dataset.type = 'phone'
  style.innerHTML =
    `
    html{
      font-size: 14px;
    }
    .HomeHeadline {
      margin: 2rem 0 2rem 0!important;
    }
    .HomeHeadline .left,
    .HomeHeadline .right{
      width: 33%!important;
    }


    .stickyNav .Nav{
      height: auto!important;
    }
    
    .other {
      margin-top: 0px!important;
    }
    .EmphasisShow {
      padding-top: 1.125rem!important;
      padding-bottom: 1.125rem!important;
    }
    
    .EmphasisShow .content_top .top_text {
      height: 2.5rem!important;
      line-height: 2.5rem!important;
    }
    .Information,
    ..Achievement,
    .Interview{
      margin: 1rem 0!important;
    }
    .Information,
    .Interview,
    .Subject,
    .Achievement{
      padding: 0rem!important;
    }
    
    
    .Achievement .content{
      margin-top: 0rem!important;
    }
    
    .Information .content .content_imgtext .content_text{
      margin:0.5rem 0;
    }
    .Information .content .content_list {
      padding: 0.5rem!important; 
    }
    .Information .content .content_list ul li::before {
      margin-right: .2rem!important;
    }
    .Information .content .content_imgtext .content_img{
      height: auto!important;
    }
    
    .Interview .content .content_card{
      height: auto!important;
      margin-bottom: 1rem!important;
    }
    .Interview .content .content_card .card_text {
      padding: 1rem!important;
    }
    .Interview .content .right .card_brief{
      margin-top: 0rem!important;
    }
    .Interview .content .content_card .card_img_left{
      height: auto!important;
    }
    // .Interview .content .el-row .content_card .card_text .card_content {
    //   display: -webkit-box;
    //   overflow: hidden;
    //   white-space: normal !important;
    //   text-overflow: ellipsis;
    //   word-wrap: break-word;
    //   -webkit-line-clamp: 7;
    //   -webkit-box-orient: vertical;
    // }
    
    
    .newsstand .content .right_top,
    .Visual .Visual_top{
      margin-top: 1rem!important;
      margin-bottom: 0.5rem!important;
    }
    
    .newsstand .content .right_bottom .bottom_text {
      padding: 0.5rem!important;
    }
    .newsstand .content .right_bottom .bottom_text hr {
      margin: 0.5rem 0!important;
    }
    .newsstand .content .right_bottom .bottom_text .right_title{
      height: auto!important;
    }
    .newsstand .content .left_bottom img,
    .newsstand .content .right_bottom img{
      height: auto!important;
    }
    .newsstand .content .left_top .head {
      padding-top: 0rem!important;
    }

    .Visual .Visual_card .card{
      margin-bottom: 1rem!important;
      height: auto!important;
    }
    .Visual .card .card_content .title{
      height: auto!important;
    }
    .Visual .card .card_content .text {
      height: auto!important;
    }
    .Visual .card img{
      height: auto!important;
    }
    
    .Subject .content .card_box{
      width: 100%!important;
      box-sizing:border-box;
    }
    .Subject .content .crad {
      margin-bottom: 1rem!important;
    }
    .Subject .content{
      text-align: center;
    }
    .Subject .content .el-col:nth-child(2){
      margin-top: -25px!important;
    }
    .Subject .content .crad hr {
      width: 10.2rem!important;
      margin: 1rem auto!important;
    }
    .Subject .content .crad p,
    .Subject .content .crad img{
      height: auto!important;
    }
    
    .Achievement .content .card{
      margin-bottom:1rem!important;
    }
    .Achievement .content .card .textol,.Achievement .content .card .title,
    .Achievement .content .card img,
    .Achievement .content .card .text{
      height: auto!important;
    }

    .Bema .content .crad{
      margin-bottom: 1rem!important;
    }
    .Bema .content .bottom {
      margin-top: .5rem!important;
    }
    
    .Reality .content .middle {
      padding: 0.5rem 1rem!important;
      margin-top: 1rem!important;
    }
    .Reality .content .right {
      margin-top: 1rem!important;
      height: auto!important;
    }
    .Reality .content .right .title {
      margin-top: 0!important;
    }
    .Reality .content .right .title_t {
      display:none!important;
    }
    .Reality .content .slideshow .crad img{
      height: auto!important;
    }

    .Book .content .right_phone {
        margin-top: 1rem!important;
    }
    

    .Footer .links {
      text-align: left!important;
      padding: 0.625rem 1.25rem!important;
  }
    .Footer .icp_sscp span{
      display: inline!important;
    }
    .Footer .sscp img{
      width: 15px!important;
    }
    .Footer .sscp .text{
      
      margin-left: 5px!important;
    }
    .Footer .icp_sscp span{
      font-size: 0.75rem!important;
      margin: 0rem!important;
    }

    .el-backtop {
      width: 40px!important;
      height: 40px!important;
    }


    .Details .container .content {
      padding: 1rem!important;
    }
    .Details .container .content .video_play .title p {
      font-size: 1.3125rem!important;
      line-height: 3rem!important;
    }
    .Details .container .content .video_play .introduce p{
      font-size: 1rem!important;
    }
    .Details .container .content .video_play .source {
      margin-top: 1rem!important;
    }
    .Details .container .content .video_play .source .share_box {
      float: none!important;
    }
    .Details .container .content .video_play .play {
      width: 100%!important;
      margin: 1.5rem auto!important;
    }
    .Details .container .content .video_play .introduce {
      margin-top: 0rem!important;
    }
    .Details .container .content .video_play{
      margin-bottom: 0!important;
    }

    .VideoHeadline .title span{
      font-size: 1.25rem!important;
    }

    .fangtan .container .card .card_content{
      padding: 1rem!important;
    }
    .fangtan .container .card{
      margin-top: 1.5rem!important;
      margin-bottom: 0.5rem!important;
    }

    .programa_one{
      padding: 2rem 0 0 0!important;
    }
    .programa_two .other_btn {
      top: auto!important;
      bottom: 2%!important;
    }
    .programa_two {
      padding: 1rem 0rem!important;
    } 

    .Newspaper .head .headl img,
    .Visual .head .headl img {
      width: 89%!important;
    }
    .Newspaper .mains .ws_bot .borbox .smil_h img,.Newspaper .Visu img{
      height: auto!important;
    }
    

    
    .xueren .kamain {
      margin-bottom: 0rem!important;
    }
    .xueren .arrow_2 {
      padding-top: 0!important;
    }
    .xueren .fam .top_left {
      margin-top: 0.5rem!important;
      width: 100%!important;
      padding: 1rem!important;
      margin-bottom: 0.5rem!important;
    }
    .xueren .fam .top_left .top_toul {
      height: auto!important;
      float: none!important;
      margin: 0 auto!important;
    }
    .xueren .fam .top_left .top_toul .touxiang {
      position: static!important;
      transform: none!important;
    }
    .xueren .fam .top_left .top_tour {
       margin-left: 0rem!important;
       padding: 1rem 0 .5rem 0!important;
       text-align: center!important;
    }
    .xueren .botss{
      margin-top: 0px!important;
    }
    .xueren .box_t,
    .xueren .kamain,
    .xueren .fam .fam_otf {
      margin-top: 0.5rem!important;
    }
    .xueren .fam .top_left{
      margin-top: 0rem!important;
    }
    .xueren .botss .box_t .arrows {
      position: static!important;
      top: auto!important;
      text-align: center!important;
      margin-top: 1rem!important;
    }
    .xueren .botss .box_t .arrows img {
      margin: 0 0.4rem!important;
    }
    .xueren .botss .box_t .arrows .swipe_l,
    .xueren .botss .box_t .arrows .swipe_r{
      float: none!important;
    }
    .xueren .XueRenHeadline .XueRenHeadline_img {
      width: 32%!important;
      padding: 0 .25rem!important;
      flex-shrink:1;
    }
    .xueren .XueRenHeadline .left {
      width: 38%!important;
      flex-shrink:1;
    }
    .xueren .XueRenHeadline .right {
      width: 38%!important;
      flex-shrink:1;
    }
    .xueren .main .arrowhead{
      margin-top: 0rem!important;
    }
    .xueren .arrowhead{
      margin-top: 1rem!important;
    }
    .Recommend .card .cover{
      height: auto!important;
    }
    .xueren .kamain .crad_content img{
      height:18.797rem!important;
    }
    .xueren .smil_s .Image img{
      height:18.234rem!important;
    }
    `;
  var head = document.getElementsByTagName("body")[0];
  head.appendChild(style);

  // document.body.appendChild(style1);
}
const removeStyle = () => {
  var head = document.getElementsByTagName("body")[0];
  const getStyle = head.getElementsByTagName('style')
  // 查找style是否存在，存在的话需要删除dom

  for (let i = 0, l = getStyle.length; i <= l; i++) {
    for (let i in getStyle) {
      if (getStyle[i].getAttribute) {
        if (getStyle[i].getAttribute('data-type') === 'phone') {
          getStyle[i].remove()
        }
      }
    }
  }
}
export { addStyle, removeStyle }