<template>
  <div class="Visual" v-if="VList.length">
    <el-row
      type="flex"
      justify="center"
      
    >
      <el-col >
        <!-- 头部 -->
        <div class="head">
          <div class="headl">
            <img src="../assets/image/line.png" alt="" />
            <div class="round"></div>
          </div>
          <div class="cent" @click="shipin_s(49)">
            <img
              src="../assets/image/home/视评.png"
              alt=""
              
            />
            <span>视评</span>
          </div>
          <div class="headl">
            <div class="round"></div>
            <img src="../assets/image/line.png" alt="" />
          </div>
        </div>

        <div class="Visual_card">
          <el-row :gutter="$store.state.isEquipment == 'pc'?20:0">
            <el-col
              :xs="24"
              :sm="8"
              :md="8"
              :lg="8"
              :xl="8"
              v-for="item in VList"
              :key="item.index"
            >
              <router-link target="_blank"
                :to="{
                  path: '/xq',
                  query: {
                    qing: item.id,
                    mian: 'shi',
                  },
                }"
              >
                <div class="card">
                  <img  v-lazy="item.pub_cover" alt="" />
                  <div class="card_content">
                    <p class="title">{{ item.SYS_TOPIC }}</p>
                    <p class="text">{{ $AwayTell(item.DESCRIPTION) }}</p>
                  </div>
                </div>
              </router-link>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import api from "@/api/index.js";
export default {
  name: "",

  components: {
    api,
  },
  props: ["sendText"],
  data() {
    return {
      VList: [],
      equipment: this.$store.state.isEquipment,
    };
  },
  watch: {
    sendText(newVal) {
      if(this.$store.state.isEquipment == 'pc'){
        this.VList = newVal;
      }else{
        this.VList = newVal.slice(0,1);
      }
    },
  },

  methods: {
    shipin_s(flag) {
      let routerJump = this.$router.resolve({
        path: "/bksjList",
        query: { id: flag },
      });
      window.open(routerJump.href,'_blank')
    },
  },
};
</script>

<style lang='scss' scoped>
.Visual {
  .head {
    width: 100%;
    // height: 2.625rem;
    display: flex;
    justify-content: center;
    padding-top: 1rem;

    .headl {
      display: flex;
      align-items: center;

      img {
        float: left;
        // width: 615px;
        width: calc(100% - 10px);
        height: 0.0625rem;
      }

      .round {
        width: 0.625rem;
        height: 0.625rem;
        border: 1px solid #d6d6d6;
        border-radius: 50%;
        float: left;
      }
    }

    .cent {
      display: flex;
      line-height: 1.9rem;
      margin: 0 1rem;
      cursor: pointer;
      white-space: nowrap;

      img {
        width: 1.7rem;
        height: 2rem;
        vertical-align: bottom;
      }

      span {
        font-size: 1.875rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #212121;
        margin-left: 0.5rem;
        vertical-align: bottom;
      }
    }
  }

  img {
    width: 100%;
    cursor: pointer;
  }

  .Visual_card {
    margin-top: 1rem;
  }

  .card {
    background: #f5f5f5;

    img {
      width: 100%;
      // height: 12rem;
      height:340px;
      // height: 17.625rem;
      vertical-align: top;
    }

    .card_content {
      padding: 0.5rem 1rem 1rem 1rem;
      cursor: pointer;

      .title {
        font-size: 1.25rem;
        font-weight: 400;
        color: #212121;
        line-height: 2.25rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        height: 4.5rem;
      }
      .title:hover{
        color:#277398;
      }

      .text {
        margin-top: 0.5rem;
        font-size: 1rem;
        font-weight: 400;
        color: #666666;
        line-height: 1.875rem;
        height: 7.2rem;
        display: -webkit-box;
        overflow: hidden;
        white-space: normal !important;
        text-overflow: ellipsis;
        word-wrap: break-word;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        text-align: justify;
      }
    }
  }
  @media screen and(max-width:1440px) {
    .card img{
      height:240px;
    }
  }
}
</style>