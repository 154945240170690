import VueRouter from 'vue-router';
import Index from '@/pages/index/Index';
const routes = [
	{
		path: '/bksj',
		component: () => import('@/pages/bksj/Index'),
	},
	{
		path: '/bksjList',
		component: () => import('@/pages/bksj/list/Index.vue'),
	},
	{
		path: '/fangtan',
		component: () => import('@/pages/fangtan/Index'),
	},
	{
		path: '/xq',
		component: () => import('@/components/xiangqing/Details'),
	},
	{
		path: '/index',
		name: 'index',
		component: Index,
	},
	{
		path: '/jiansuo',
		component: () => import('@/pages/jiansuo/Index'),
	},
	{
		path: '/woshuo',
		component: () => import('../pages/woshuo/Index.vue'),
	},
	{
		path: '/xueren',
		component: () => import('@/pages/xueren/Index'),
	},
	{
		path: '/zhibo',
		component: () => import('@/pages/zhibo/Index'),
	},
	{
		path: '/shipinzu',
		component: () => import('@/pages/zhibo/list'),
	},
	{
		path: '/zhiboDetails',
		component: () => import('@/components/xiangqing/Details'),
	},
	{
		path: '/zhuanti',
		component: () => import('@/pages/zhuanti/Index'),
	},
	{
		path: '/zhuantiList',
		component: () => import('@/pages/zhuanti/list/Index'),
	},
	{
		path: '/zixun',
		component: () => import('@/pages/zixun/Index'),
	},
	{
		path: '/zixunDetails',
		component: () => import('@/components/xiangqing/Details'),
	},
	{
		path: '/xuerenlist',
		component: () => import('@/pages/xueren/list/Index'),
	},
	{
		path: '/jiansuo',
		component: () => import('@/pages/jiansuo/Index.vue'),
	},
	{
		path: '/homeList',
		component: () => import('@/pages/index/list/Index.vue'),
	},
	{
		path: '/',
		redirect: '/index',
	},
	{
		path: '*',
		redirect: '/index',
	},
	{
		path: '/privateList',
		component: () => import('@/pages/private/Index'),
	},
	{
		path: '/privateDetails',
		component: () => import('@/components/xiangqing/Details'),
	},
];

export default new VueRouter({
	routes,
});
