<!-- 重点展示区 -->
<template>
  <div class="EmphasisShow mainWidth" :class="{ loaded: firstlis.length }">
    <el-row type="flex" justify="center">
      <el-col>
        <div class="content_top" v-for="(item, b) in firstlis" :key="b">
          <a
            target="_blank"
            :href="item.XINWENZIXUN_LINK_ADDRESS"
          >
            <img :src="item.pub_cover" alt="" class="cover" />
            <div class="top_text">
              <p>{{ item.SYS_TOPIC }}</p>
            </div>
          </a>
        </div>

        <el-row :gutter="$store.state.isEquipment == 'pc' ? 20 : 0">
          <el-col
            :xs="24"
            :sm="8"
            :md="8"
            :lg="8"
            :xl="8"
            v-for="(item, b) in impoos"
            :key="item.index"
          >
            <a
              target="_blank"
              :href="item.XINWENZIXUN_LINK_ADDRESS"
              class="link"
            >
              <div :class="['content_bottom', b == 1 ? 'content_bottom2' : '']">
                <div class="bottom_img">
                  <img v-lazy="item.pub_cover" alt="" />
                </div>
                <div class="bottom_text">
                  <span>{{ item.SYS_TOPIC }}</span>
                </div>
                <div class="bottom_play">
                  <img src="@/assets/image/home/播放(2).png" alt="" />
                </div>
              </div>
            </a>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import api from "@/api/index.js";
export default {
  name: "",

  components: {},

  data() {
    return {
      impoos: [],
      firstlis: "",
    };
  },

  created() {},

  methods: {},
  mounted() {
    api
      .post("spc/cms/publish/queryList.do", {
        s: 2,
        c: 91,
      })
      .then((res) => {
        this.firstlis = res.result.slice(0, 1); //重点区域第一个大图

        this.impoos = res.result.slice(1, 4); //重点区域后3个小图
      });
  },
};
</script>

<style scoped lang="scss">
.EmphasisShow {
  background-size: 100% 100%;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  box-sizing: border-box;
  height: 1003px;
  .link {
    display: block;
  }
  .bottom_img img {
    width: 100%;
    aspect-ratio: 4/3;
    display: block;
    height: 157px;
  }

  .content_top {
    // height: 40.625rem;
    position: relative;
    cursor: pointer;
    .cover {
      width: 100%;
      vertical-align: top;
      height: 696px;
    }
    .top_text {
      width: 100%;
      height: 3.75rem;
      line-height: 3.75rem;
      background: rgba(31, 44, 84, 0.8);
      position: absolute;
      bottom: 0;

      p {
        font-size: 1.3rem;
        font-weight: bold;
        color: #ffffff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 0.5rem;
      }
    }
  }

  .content_bottom {
    padding: 1rem;
    margin: 0.625rem 0rem;
    background: linear-gradient(90deg, #202e5a, #1a1f33);
    position: relative;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      background: linear-gradient(90deg, #510e0e, #ad241e);
    }

    .bottom_img {
      float: left;
      width: 49%;
      height: 100%;
    }

    .bottom_text {
      float: left;
      width: 45%;
      color: #ffffff;
      // padding-top: .8125rem;
      padding-left: 5%;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.875rem;

      span {
        display: -webkit-box;
        overflow: hidden;
        white-space: normal !important;
        text-overflow: ellipsis;
        word-wrap: break-word;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    .bottom_play {
      position: absolute;
      bottom: 4%;
      left: 50%;

      img {
        width: 2.5rem;
        vertical-align: top;
      }
    }
  }
}
.EmphasisShow.loaded {
  background: url("../../assets/image/home/bg.png");
}
@media screen and (max-width: 1440px) {
  .EmphasisShow {
    height: 737.5px;
    .content_top .cover {
      height: 497px;
    }
  }
  .EmphasisShow .bottom_img img{
    height: 108px;
  }
}
.phone .EmphasisShow {
  height: auto;
  min-height: 500px;
  .content_top .cover {
    height: auto;
  }
  .bottom_img img{
    height: auto;
  }
}
</style>