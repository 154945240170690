<template>
  <!-- 用来判断是pc端的导航还是移动端导航 -->
  <div>
    <div class="Nav" v-if="$store.state.isEquipment !== 'phone'">
      <Navigation />
    </div>
    <div class="Nav" v-else>
      <NavigationPhone />
    </div>
  </div>
    <!-- <div class="home_Nav">
      <Navigation v-if="this.equipment == 'phone' ? false : true"/>
      <NavigationPhone v-if="this.equipment == 'phone' ? true : false"/>
    </div> -->
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import NavigationPhone from "@/components/Navigation_phone.vue"

export default {
  name: '',

  components: {
    Navigation,
    NavigationPhone
  },

  data() {
    return {
      equipment: this.$store.state.isEquipment,
    }
  },

  created() {},

  methods: {},

}

</script>