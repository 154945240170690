<!-- 讲坛 -->
<template>
  <div class="Bema" v-if="shou_jiang.length">
    <el-row type="flex" justify="center">
      <el-col>
        <!-- 首页讲坛栏目跳转 -->
        <router-link
          target="_blank"
          :to="{ path: '/homeList', query: { id: 67 } }"
        >
          <HomeHeadline :title="require('@/assets/image/home/讲坛.png')" />
        </router-link>

        <div class="content">
          <el-row :gutter="$store.state.isEquipment == 'pc' ? 20 : 0">
            <el-col
              :xs="24"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="12"
              v-for="item in shou_jiang"
              :key="item.index"
            >
              <div class="crad">
                <div class="top">
                  <el-row :gutter="$store.state.isEquipment == 'pc' ? 14 : 0">
                    <router-link
                      target="_blank"
                      :to="{
                        path: '/xq',
                        query: {
                          qing: item.id,
                          mian: 'shou_jiang',
                        },
                      }"
                    >
                      <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
                        <img v-lazy="item.pub_cover" alt="" />
                      </el-col>
                      <el-col :xs="24" :sm="10" :md="14" :lg="14" :xl="14">
                        <p class="title">{{ item.SYS_TOPIC }}</p>
                        <p class="text" v-html="item.DESCRIPTION"></p>
                      </el-col>
                    </router-link>
                  </el-row>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row
            :gutter="$store.state.isEquipment == 'pc' ? 20 : 0"
            class="bottom"
          >
            <el-col
              :xs="24"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="12"
              v-for="item in list"
              :key="item.index"
            >
              <router-link
                target="_blank"
                :to="{
                  path: '/xq',
                  query: {
                    qing: item.id,
                    mian: 'shou_jiang',
                  },
                }"
                ><p>{{ item.SYS_TOPIC }}</p></router-link
              >
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import HomeHeadline from "@/components/HomeHeadline.vue";
import api from "@/api/index.js";
export default {
  components: {
    HomeHeadline,
  },
  props: {},
  data() {
    return {
      shou_jiang: "",
      list: [],
    };
  },
  mounted() {
    api
      .post("spc/cms/publish/queryList.do", {
        s: 2,
        c: 67,
      })
      .then((res) => {
        if (this.$store.state.isEquipment == "pc") {
          this.shou_jiang = res.result.slice(0, 2);
          this.list = res.result.slice(2, 6);
        } else {
          this.shou_jiang = res.result.slice(0, 1);
          this.list = res.result.slice(1, 4);
        }
      });
  },
};
</script>
<style scoped lang="scss">
.Bema {
  background: url("../../assets/image/home/pic(7).png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  // margin-top: 2rem;
  .content {
    margin-top: 1rem;

    .top {
      margin-bottom: 0.625rem;

      img {
        width: 100%;
        aspect-ratio: 4/3;
        height: 209px;
      }

      .title {
        font-size: 1.25rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 2rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        margin-bottom: 0.5rem;
        height: 4rem;
        text-align: justify;
      }

      .title:hover {
        color: #277398;
      }

      .text {
        font-size: 1rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 1.75rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        text-align: justify;
      }
    }

    .bottom {
      p {
        color: #333333;
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 2.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: 1px solid #eeeeee;
      }

      p:hover {
        color: #277398;
        cursor: pointer;
      }

      p::before {
        content: "\2022 ";
        font-size: 1.25rem;
        color: #bdc5cd;
        margin-right: 0.5rem;
      }
    }
  }
}
@media screen and(max-width: 1440px) {
  .Bema .content .top img {
    height: 147px;
  }
}
.phone .Bema .content .top img {
  height: auto;
}
</style>