var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Information"},[_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',[_c('router-link',{attrs:{"target":"_blank","to":{ path: '/zixun' }}},[_c('HomeHeadline',{attrs:{"title":require('@/assets/image/home/矩形.png')}})],1),_c('div',{staticClass:"content"},[_vm._l((_vm.zixun_list),function(item){return _c('router-link',{key:item.index,staticClass:"normal",attrs:{"target":"_blank","to":{
            path: '/xq',
            query: {
              qing: item.id,
              mian: 'shou_zi',
            },
          }}},[_c('div',{staticClass:"content_imgtext"},[_c('div',{staticClass:"content_img"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.pub_cover),expression:"item.pub_cover"}],attrs:{"alt":""}})]),_c('div',{staticClass:"content_text"},[_c('p',[_vm._v(_vm._s(item.SYS_TOPIC))])])])])}),_c('div',{staticClass:"special"},[_c('div',{staticClass:"content_list"},[_c('ul',_vm._l((_vm.zixun_wen),function(item,b){return _c('router-link',{key:item.index,attrs:{"target":"_blank","to":{
                  path: '/xq',
                  query: {
                    qing: item.id,
                    mian: 'shou_zi',
                  },
                }}},[_c('li',{class:b == 0 ? 'firstli' : ''},[_vm._v(_vm._s(item.SYS_TOPIC))])])}),1)])])],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }