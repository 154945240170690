function delHtmlTag(str,startNum,endNum) {
   var Str = str.replace(/<[^>]+>/g, '').replace(/&nbsp;/ig, "")
   if(Str.length <= endNum || endNum == undefined){
    var newstr = Str.substr(startNum,endNum)
   }else{
    var newstr = Str.substr(startNum,endNum) + "..."
   }
   
   return newstr
}
export default delHtmlTag