<template>
  <div id="app" :class="{
    other: isShow && $route.path !== '/privateDetails',
    jainsuo: !isShow,
    phone: $store.state.isEquipment == 'phone'
  }">
    <!-- 顶部导航 -->
    <Column v-if="isShow" />
    <!-- 首页banner -->
    <div class="indexBanner" v-show="$route.path === '/index'"></div>
    <!-- 页面内部导航条 -->
    <NavType v-if="showNavType" class="stickyNav"></NavType>
    <div class="router-view-wrap">
      <keep-alive include="IndexPage">
        <router-view class="router-view"></router-view>
      </keep-alive>
    </div>
    <Scroll />
    <Footer v-if="isShow && $route.path !== '/privateDetails'" />
  </div>
</template>

<script>
import Column from "@/components/Column.vue";
import Footer from "@/components/Footer.vue";
import Scroll from "@/components/Scroll.vue";
import NavType from "@/components/NavType.vue";
import { addStyle, removeStyle } from "./style";

export default {
  name: "App",
  components: {
    Column,
    Footer,
    Scroll,
    NavType,
  },
  data() {
    return {
      isShow: true,
      screenWidth: null,
    };
  },

  watch: {
    $route: {
      handler(to, from) {
        let newUrl = to.fullPath.split("?")[0];
        if (newUrl == "/jiansuo") {
          this.isShow = false;
        } else {
          this.isShow = true;
        }
      },
      immediate: true,
      deep: true,
    },
    screenWidth: {
      handler: function (n) {
        this.changeScreen(n);
      },
      immediate: true,
    },
  },
  created() {
    this.screenWidth = document.body.clientWidth;
    this.changeScreen(this.screenWidth);
  },
  mounted() {
    window.onresize = () => {
      this.screenWidth = document.body.clientWidth;
    };
  },
  methods: {
    changeScreen(n) {
      this.boxWidth = document.getElementById("app");
      if (
        window.navigator.userAgent.match(
          /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        console.log("phone");
        this.$store.commit("setIsEquipment", "phone");
        document.documentElement.style.fontSize =
            (document.body.clientWidth / 390) * 14 + "px";
        addStyle();
      } else {
        console.log("pc");
        this.$store.commit("setIsEquipment", "pc");
        this.$store.commit("setIsSmallPc", window.innerWidth <= 1440);
      }
    },
  },
  computed: {
    showNavType() {
      console.log(this.$store.state.isEquipment);
      console.log(this.$route.path);
      if (
        this.$store.state.isEquipment === "pc" &&
        !["/privateDetails", "/jiansuo"].includes(this.$route.path)
      ) {
        return true;
      }
      if (this.$store.state.isEquipment === "phone") {
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss">
@import url(./assets/base.css);

html {
  font-size: 16px;
}

.mainWidth {
  width: 1400px;
  margin: auto;
  box-sizing: border-box;
}

.phone .mainWidth {
  width: 100%;
}
#app.phone{
  max-width:480px;
  margin:auto;
}

.indexBanner {
  width: 100%;
  padding-bottom: 27%;
  height: 0;
  background: url("~@/assets/image/indexBanner.jpg") no-repeat center center/cover;
}

@media screen and (max-width: 1440px) {
  html {
    font-size: 14px;
  }

  #app {
    min-width: 1100px;
  }

  #app.phone {
    min-width: auto;
  }

  .Footer {
    .el-row {
      font-size: 1.1rem;
    }

    .icp {
      font-size: 0.85rem;
    }
  }

  .mainWidth {
    width: 1000px;
  }
}

* {
  padding: 0;
  margin: 0;
}

#app {
  font-family: "Microsoft YaHei", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

.jainsuo {
  margin-top: 0rem;
}

.other {
  margin-top: 3.75rem !important;
}

.crumbs {
  padding-top: 1.25rem;

  .el-breadcrumb {
    font-size: 1rem;
  }

  .el-breadcrumb__separator {
    margin: 0 0.5625rem;
    font-weight: 700;
    color: #c0c4cc;
  }
}

.el-row {
  .el-col {
    box-sizing: border-box;
  }
}

.el-breadcrumb__item {

  .el-breadcrumb__inner,
  .el-breadcrumb__inner is-link {
    font-weight: 400;
    color: #606266;
  }

  .el-breadcrumb__item:last-child,
  .el-breadcrumb__inner,
  .el-breadcrumb__inner.is-link {
    font-weight: 400 !important;
    cursor: pointer;
    color: #999999;
  }

  .el-breadcrumb__item:last-child,
  .el-breadcrumb__inner.is-link:hover {
    // font-weight: 400!important;
    cursor: pointer;
    color: #409eff;
    font-weight: 700 !important;
  }

  .el-breadcrumb__inner:hover {
    cursor: pointer;
    color: #409eff;
    font-weight: 700 !important;
  }

  .el-breadcrumb__separator {
    font-weight: 400 !important;
  }

  .el-breadcrumb__inner is-link:hover {
    cursor: pointer !important;
  }
}

.router-view-wrap {
  min-height: 100vh;
}</style>