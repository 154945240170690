<!-- 好书 -->
<template>
  <div class="Book" v-if="list.length">
    <el-row type="flex" justify="center">
      <el-col>
        <!-- 首页好书栏目 -->
        <router-link
          target="_blank"
          :to="{ path: '/homeList', query: { id: 69 } }"
        >
          <HomeHeadline :title="require('@/assets/image/home/好书.png')" />
        </router-link>

        <div class="content">
          <el-row>
            <el-col>
              <div class="right swiper-wrapper" v-if="this.equipment == 'pc'">
                <Swiper :options="swiperOption">
                  <SwiperSlide
                    v-for="(item, index) in list"
                    :key="item.index"
                    class="swiper-no-swiping"
                  >
                    <div align="center" class="crad">
                      <router-link
                        target="_blank"
                        :to="{
                          path: '/xq',
                          query: {
                            qing: item.id,
                            mian: 'shou_shu',
                          },
                        }"
                      >
                        <img
                          :src="item.pub_cover"
                          alt=""
                          v-if="index === 0 || index === list.length - 1"
                        />
                        <img v-lazy="item.pub_cover" alt="" v-else />
                        <div class="crad_content">
                          <p>{{ item.SYS_TOPIC }}</p>
                        </div>
                      </router-link>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>

              <!-- 轮播图区域手机端 -->
              <div class="right_phone" v-if="this.equipment == 'phone'">
                <Swiper :options="swiperOption_2">
                  <SwiperSlide v-for="item in list" :key="item.index">
                    <div align="center" class="crad">
                      <router-link
                        class="link"
                        target="_blank"
                        :to="{
                          path: '/xq',
                          query: {
                            qing: item.id,
                            mian: 'shou_shu',
                          },
                        }"
                      >
                        <img v-lazy="item.pub_cover" alt="" />
                        <div class="crad_content">
                          <p>{{ item.SYS_TOPIC }}</p>
                        </div>
                      </router-link>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import HomeHeadline from "@/components/HomeHeadline.vue";
import api from "@/api/index.js";
export default {
  components: {
    HomeHeadline,
    // Swiper,
    // SwiperSlide,
  },
  props: {},
  data() {
    return {
      shou_hao: "",
      list: [],
      swiperOption: {
        slidesPerGroup: this.$store.isEquipment === "phone" ? 1 : 4,
        initialSlide: 0,
        loop: true,
        EffectCards: true,
        slidesPerView: this.$store.isEquipment === "phone" ? 1 : 4,
        spaceBetween: 20,
        noSwiping: true,
      },
      swiperOption_2: {},
      equipment: this.$store.state.isEquipment,
    };
  },
  computed: {},
  methods: {},
  mounted() {
    api
      .post("spc/cms/publish/queryList.do", {
        s: 2,
        c: 69,
      })
      .then((res) => {
        console.log("eeee", res);
        this.list = res.result;
      });
  },
};
</script>
<style scoped lang="scss">
.Book {
  background: url("../../assets/image/home/pic(7).png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .content {
    margin-top: 16px;

    .left {
      background: #f1f4fb;
      padding: 19px 16px;

      a {
        color: #35292b;
      }
      a:hover {
        color: #277398;
      }

      ul {
        li {
          font-size: 1.25rem;
          cursor: pointer;
          color: #35292b;
          line-height: 48px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          list-style: none;
          border-bottom: 1px solid #ebedf0;
        }

        li:first-child {
          font-weight: bold;
        }

        li::before {
          content: "\2022  ";
          font-size: 22px;
          color: #e48e31;
          margin-right: 3.2px;
        }
      }
    }

    .right {
      position: relative;

      .crad {
        img {
          width: 100%;
          aspect-ratio: 1/1;
          height: 335px;
          display: block;
        }
        @media screen and(max-width: 1440px) {
          img {
            height: 235px;
          }
        }
        .crad_content {
          // width: 100%;
          padding: 8px 16px 16px 16px;
          background: #f1f4fb;

          p {
            font-size: 20px;
            font-weight: 400;
            color: #000000;
            line-height: 32px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            height: 64px;
          }
          p:hover {
            color: #277398;
          }
        }
      }

      .arrows {
        position: absolute;
        top: 50%;
        width: 100%;

        img {
          width: 40px;
          height: 40px;
        }

        .swiper-button-prev2 {
          margin-left: -50px;
          cursor: pointer;
        }

        .swiper-button-next2 {
          margin-right: -50px;
          float: right;
          transform: rotateZ(180deg);
          cursor: pointer;
        }
      }
    }

    .right_phone {
      margin-top: 24px;
      margin-top: 32px;

      .crad {
        .link {
          display: block;
          img {
            vertical-align: top;
          }
        }
        img {
          width: 100%;
        }
        .crad_content {
          margin: 0.5rem 0 1.5rem;
          color: #2c3e50;
          font-size: 1.25rem;
        }
      }
    }
  }
}
</style>