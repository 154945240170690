import {
  Button,
  Row,
  Col,
  Input,
  Menu,
  MenuItem,
  Backtop,
  Drawer,
  Breadcrumb,
  BreadcrumbItem,
  Pagination,
} from 'element-ui'
const element = {
  install: (Vue) => {
    Vue.use(Button);
    Vue.use(Row);
    Vue.use(Col);
    Vue.use(Input);
    Vue.use(Menu);
    Vue.use(MenuItem);
    Vue.use(Backtop);
    Vue.use(Drawer);
    Vue.use(Breadcrumb);
    Vue.use(BreadcrumbItem);
    Vue.use(Pagination);
  }
}
export default element