var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content content-second"},[(_vm.list2.length)?_c('Swiper',{class:_vm.$store.state.isEquipment === 'pc' ? '' : 'phone',attrs:{"options":_vm.$store.state.isEquipment === 'pc' ? _vm.swiperOption : _vm.swiperOption_phone}},_vm._l((_vm.list2),function(item,index){return _c('SwiperSlide',{key:index,staticClass:"page"},[_c('router-link',{staticClass:"crad",attrs:{"target":"_blank","to":{
          path: '/xq',
          query: {
            qing: item.id,
            mian: `shx${2}`,
          },
        }}},[_c('img',{attrs:{"src":item.pub_cover}}),_c('div',[_c('p',{staticClass:"name"},[_vm._v(_vm._s(item.FUBIAOTI))]),_c('p',{staticClass:"title1"},[_vm._v(_vm._s(item.SYS_TOPIC))]),_c('p',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$AwayTell(item.DESCRIPTION))+" ")]),_c('img',{staticClass:"more",attrs:{"src":require("./more.png"),"alt":""}})])])],1)}),1):_vm._e(),(_vm.$store.state.isEquipment === 'pc')?_c('div',{staticClass:"arrows"},[_c('img',{staticClass:"swiper-button-next1",attrs:{"src":require("@/assets/image/home/arrow_left.png"),"alt":""}}),_c('img',{staticClass:"swiper-button-prev1",staticStyle:{"transform":"rotateZ(180deg)"},attrs:{"src":require("@/assets/image/home/arrow_left.png"),"alt":""}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }