<!-- 访谈 -->
<template>
  <div class="Interview">
    <el-row type="flex" justify="center">
      <el-col>
        <!-- 首页访谈栏目跳转 -->
        <router-link target="_blank" :to="{ path: '/fangtan' }">
          <HomeHeadline :title="require('@/assets/image/home/访谈.png')" />
        </router-link>

        <div class="content">
          <el-row :gutter="$store.state.isEquipment == 'pc' ? 10 : 0">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="content_card left" v-for="a in firstfang" :key="a.index">
                <router-link target="_blank" :to="{
                  path: '/xq',
                  query: {
                    qing: a.id,
                    mian: 'shou_fang',
                  },
                }">
                  <div class="card_img card_img_left">
                    <img v-lazy="a.pub_cover" />
                  </div>

                  <div class="card_text">
                    <p class="card_brief">{{ a.SYS_TOPIC }}</p>
                    <p class="card_content">
                      {{ $AwayTell(a.DESCRIPTION, 0, 500) }}
                    </p>
                  </div>
                </router-link>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-if="$store.state.isEquipment == 'pc'">
              <div>
                <el-row :gutter="$store.state.isEquipment == 'pc' ? 10 : 0">
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-for="item in remain" :key="item.index">
                    <router-link target="_blank" :to="{
                      path: '/xq',
                      query: {
                        qing: item.id,
                        mian: 'shou_fang',
                      },
                    }">
                      <div class="content_card">
                        <div class="card_img card_img_right">
                          <img v-lazy="item.pub_cover" />
                        </div>

                        <div class="card_text">
                          <p class="card_brief">{{ item.SYS_TOPIC }}</p>
                          <p class="card_content">
                            {{ $AwayTell(item.DESCRIPTION, 0, 400) }}
                          </p>
                        </div>
                      </div>
                    </router-link>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import HomeHeadline from "@/components/HomeHeadline.vue";
import api from "@/api/index.js";
export default {
  name: "",

  components: {
    HomeHeadline,
  },

  data() {
    return {
      firstfang: [],
      remain: "",
    };
  },

  created() { },

  methods: {},
  mounted() {
    api
      .post("spc/cms/publish/queryList.do", {
        s: 2,
        c: 51,
      })
      .then((res) => {
        // 第一个
        this.firstfang.push(res.result.shift());
        // 剩下的
        this.remain = res.result.slice(0, 4);
      });
  },
};
</script>

<style scoped lang="scss">
.Interview {
  background: url("../../assets/image/home/pic(7).png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 729px;

  img {
    width: 100%;
    display: block;
  }

  .content {
    margin-top: 1rem;

    .content_card {
      margin-bottom: 8px;
      position: relative;
      overflow: hidden;

      .card_img {
        overflow: hidden;

        img {
          transition: transform 0.5s;
          -webkit-transition: transform 0.5s;
          /* Safari */
          vertical-align: top;
          height: 257px;
        }
      }

      .card_text {
        padding: 1rem 1.2rem 1rem 1.2rem;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: url("../../assets/image/fangtanback.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        height: 6rem;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;

        /* Safari */
        .card_brief {
          font-weight: 400;
          color: #212121;
          line-height: 2rem;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          font-weight: 700;
          font-size: 1.25rem;
          color: #fff;
          height: 4rem;
        }

        .card_content {
          margin-top: 1rem;
          font-size: 1rem;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          line-height: 2rem;
          text-align: justify;
        }
      }
    }

    .content_card.left {
      .card_content {
        -webkit-line-clamp: 10;

      }

      .card_img img {
        height: 521px;
      }
    }

    .content_card:hover img {
      transform: scale(1.1);
    }

    .content_card:hover .card_text {
      height: 100%;
      background-color: rgba(45, 93, 130, 0.7);
      background-image: none;
    }
  }
}

@media screen and(max-width:1440px) {
  .Interview {
    height: 554px;

    .content .content_card {
      .card_text .card_content {
        -webkit-line-clamp: 3;
      }

      .card_img img {
        height: 182px;
      }
    }

    .content .content_card.left {
      .card_text .card_content {
        -webkit-line-clamp: 10;
      }

      .card_img img {
        height: 371px;
      }
    }
  }
}

.phone .Interview {
  min-height: 300px;
  height: auto;

  .content .content_card {
    .card_text .card_content {
      -webkit-line-clamp: 6;
    }

    .card_img img {
      height: auto;
    }
  }
}
</style>