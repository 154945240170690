<template>
  <div class="HomeHeadline">
    <el-row type="flex" justify="center">
      <el-col :span="24">
        <div>
          <img src="../assets/image/home/左.png" alt="" class="left" />
          <img  v-lazy="title" alt="" class="HomeHeadline_img" />
          <img src="../assets/image/home/右.png" alt="" class="right"/>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "",

  components: {},

  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },

  created() {},

  methods: {},
};
</script>

<style scoped lang="scss">
.HomeHeadline {
  width: 100%;
  margin: 4rem 0;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .left{
    width: 30rem;
  }
  .right{
    width: 30rem;
  }
  .HomeHeadline_img{
    height: 4.4rem;
  }
}
@media screen and(max-width:750px) {
  .HomeHeadline .HomeHeadline_img{
    height: 3rem;
  }
}

</style>